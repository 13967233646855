.MenteeMyProfile {    
    .editIcon {
        position: absolute;
        bottom: 8px;
        right: 6px;
        background: #fff;
        border-radius: 50%;
        padding: 2px 4px;
    }
    .ProfileBadge span {
        font-size: 30px;
    }
    .editIcon {
        position: absolute;
        bottom: 8px;
        right: 6px;
        background: #fff;
        border-radius: 50%;
        padding: 2px 4px;
    }
    .editbtn {
        color: #000;
        background: transparent;
        border: none;
    }
    .ProfileMeta p {
        margin-bottom: 4px;
        font-size: 16px;
    }
    .form-control {
        border-color: rgba(112, 112, 112, 0.2);
        margin: 12px 0;
    }    
    .edit-btn {
        color: #000;
        font-weight: 600;
    }
    .view-btn {
        color: #1CAF00;
        margin-right: 20px;
        font-weight: 600;
    }
    .divider {
        background: #707070;
        display: inline-block;
        width: 100%;
        height: 1px;
        margin-bottom: 30px;
        margin-top: 10px;
        opacity: 0.2;
    }
    .editbtn {
        color: #000
    }
    .op7 {
        opacity: 0.7;
    }    
    .welcomeBg{
        background: #cef1ea;
        padding: 15px;
        margin-bottom: 30px;
        border-radius: 15px;
    }
    .WelText{
        font-weight: 600;
        font-size: 25px;
        color: #000;
    }
    .paraWel {
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        color: #000;
    }
    .padd25{
        padding:25px 0px;
    }
    .Box210 {
        width: calc(100% - 1000px);
    }
}
@media(max-width:1366px){
    .MenteeMyProfile {
        .padd25 {
            padding: 25px 0px 25px 40px;
        }
    }
}
@media(max-width:1199px){
    .MenteeMyProfile {
        .padd25 {
            padding: 25px 0px 25px 25px;
        }
        .welcomeBg {
            margin: 20px 0;
        }
        .MiniPic {
            width: 100%;
        }
    }
}
@media(max-width:991px){
    .MenteeMyProfile {
        .padd25 {
            padding: 25px 0px 25px 30px;
        }
    }
}
@media(max-width:768px){
    .MenteeMyProfile {
        .padd25 {
            padding: 5px 0px 5px 10px;
        }
        .ProfileBadge {
            width: 40px;
        }
        .editbtn {
            font-size: 13px;
            display: block;
            text-align: left;
        }
        .editIcon {
            bottom: -5px;
            right: -10px;

        }
        .ProfileBadge span {
            font-size: 20px;
        }
        .form-control {
            padding: 8px 10px;
            height: auto;
            font-size: 14px;
        }
        label {
            font-size: 12px;
        }
        .ProfileMeta p {
            margin-bottom: 4px;
            font-size: 12px;
        }        
    }
}
@media(max-width:767px){
    .MenteeMyProfile {
        .WelText {
            font-size: 20px;
        }
        .MiniPic {
            width: 50%;
        }
        .padd25 {
            padding: 5px 0px 5px 20px;
        }
        label {
            font-size: 16px;
        }
        .ProfileMeta p {
            margin-bottom: 4px;
            font-size: 16px;
        }
        .welcomeBg {
            padding: 10px;
        }        

    }
}