.AboutMetaInfo {
    background: #FFFFFF;
    padding-top: 30px;
    padding-bottom: 50px;
}

.AboutMetaInfo {
    .Max-Width{
        max-width: 100%;
    } 
    .AboutText{
        font-size: 19px;
        line-height: 40px;
        font-weight: 300;
        color:#000000;
    }
    .AboutHedding h4{
        font-size: 50px;
        font-weight: 400;
    }    
    .AboutBottom{
        position: relative;
    }
    .Quote {
        width: 95px;
        height: 79px;
        position: absolute;
        left: -60px;
        top: -30px;
    }  
    .AboutBottom p {
        font-size: 30px;
        line-height: 50px;
        font-weight: 300;
        color: #555555;            
    }
    .AboutBottom h4{
        font-size: 30px;
        color: #555555;
        font-weight: bold;
    }
    .BgSet {
        padding: 90px 0;
    } 
}

@media(max-width:1024px){
    .AboutMetaInfo{
        .Quote {
            width: 95px;
            height: 79px;
            position: absolute;
            left: 0px;
            top: -30px;
        }
    }
}
@media(max-width:768px){
    .AboutMetaInfo{
        .AboutBottom p {
            font-size: 26px;            
        }
        .AboutBottom h4 {
            font-size: 25px;    
        }    
    }
}
@media(max-width:767px){
    .AboutMetaInfo{
        .AboutHedding h4 {
            font-size: 40px;
        }
        .AboutBottom p {
            font-size: 20px;
            line-height: 40px;
        }
        .AboutBottom h4 {
            font-size: 20px;
        }
    }
}
@media(max-width:414px){
    .AboutMetaInfo{
        .AboutHedding h4 {
            font-size: 30px;
        }        
    }
}


