#header {
    .userImg {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50px;
        position: relative;
    }
    .userImg img {
        position: absolute;
        left: 0;
        top: 0;
        min-width: 100%;
        min-height: 100%;
        max-width: 120%;
        max-height: 150%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .userInfo {
        display: flex;
        margin-left: 40px;
        cursor: pointer;
    }
    .infoSec {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
    }
    .infoSec > h5 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0px;
    }
    .infoSec > span {
        opacity: 0.5;
        font-size: 14px;
    }
    .infoSec > a {
        font-size: 12px;
        color: #000;
        text-decoration: underline;
        padding: 0;
        margin: 0;
    }
    .navbar .dropdown ul a {
        color: #000;
        display: inline-block;
    }
    .nameAlpha {
        width: 100%;
        height: 100%;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        background: #fed703;
    }
    .nav-link.BellIcon {
        margin-right: -32px;
        margin-left: 12px;
    }
    .navbar {
        padding: 0;
        float: right;
    }
    .logo {
        line-height: 0;
        float: left;
    }
    .BaarNav {
        font-size: 20px;
        color: #000;
        background: #fff9d6;
        padding: 15px;
    }
    .TimesNone{
        display: none;
    }
    .MyBaar {
        background: #ffd700;
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        border-radius: 4px;
        color: #000;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, .1);
        display: none;
        position: fixed;
        right: 30px;
    }
    .HeadBell {
        display: block;        
    }
    li.Bells {
        display: none;
    }
    .SiddNone {
        display: none!important;
    }
    .SignUp{
        display: none!important;
    }
}
@media(max-width:991px){
    #header {
        .MyBaar {
            width: 35px;
            height: 35px;
            line-height: 35px; 
            display: block;
            border-radius: 50px;
        }
        .TimesNone{
            display: block;
            font-size: 16px;
            margin-left: 0px;
        }
        .userInfo {
            margin-left: 0;
        }
        .BellNone {
            display: none;
        } 
        .BellImg {
            width: 25px;
            height: 20px;
        }
        .SiddNone {
            display: block!important;
        } 
        .SiddNone2 {
            display: none!important;
        } 
        .SiddNone .userImg {
            float: left;
            margin-right: 10px;
        }
    }
}
@media(max-width:768px){
    #header {
        li.Bells {
            display: block;
        }   
        .HeadBell {
            display: block;           
            width: 30%;
        }   
        .HeadBell ul {
            margin-bottom: 0;
        }  
        .HeadBell ul li{
            list-style: none;
        }
    }
}
@media(max-width:767px){
    #header {
        .nav-link.BellIcon {
            margin-right: 0;
            margin-left: 0;
            padding: 0.5rem 0;
        }
        .HeadBell {
            display: block!important;
            width: 50%;            
        }              
    }
}
@media(max-width:500px){
    #header {
        li.Bells {
            display: block;
        }
        .logo img {
            height: 40px;
        }
        .userInfo {
            float: right;            
            z-index: 99999;
        }
        .SiddNone .infoSec > h5 {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0px;
        }
        .SiddNone .userImg {
            width: 30px;
            height: 30px;
        }
        .HeadBell {
            display: block!important;
            width: 35%;            
        }
        
    }
}
@media(max-width:414px){
    #header {
        .HeadBell {
            display: block!important;
            width: 40%;            
        }        
    }
}
@media(max-width:360px){
    #header {       
        .logo img {
            height: 40px;
        }
        .HeadBell {
            display: block!important;
            width: 42%;            
        }        
    }
}
@media (max-width: 320px){
    #header {
        .MyBaar {
            width: 30px;
            height: 30px;
            line-height: 30px;
            right: 20px;            
        }
    }
}