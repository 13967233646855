.JoinUs {
    background:transparent;
    padding-top: 30px;
    padding-bottom: 50px;
}

.JoinUs {

    .Max-Width{
        max-width: 100%;
    }    
    .JoinBox h4 {
        display: inline-block;
        font-size: 50px;
        font-weight: 500;
    }    
    .JoinBox img {
        width: 550px;
    }    
    .JoinBox p {
        font-size: 20px;
        line-height: 40px;
        font-weight: 300;
    }
    .JoinBox a {
        display: inline-block;
        background: #fed703;
        color: #000;
        padding: 4px 30px;
        border-radius: 20px;
        margin: 0px 0 20px;
        text-decoration: none;
        font-weight: bold;
        transition: all .5s ease-in-out;
        border: 1px solid #fed703;
    }
    .JoinBox a:hover {
        border-color: #000;
        background: transparent;
    }    
}

@media(max-width:1024px){
    .JoinUs{
        padding: 30px 10px;
        .JoinBox img {
            width:100%;
        }
        .JoinBox p {
            font-size: 18px;
            line-height: 30px;           
        }
        .joinImg{
            width: 100%;
            margin-bottom: 20px;
        }
    }
}

@media(max-width:767px){
    .JoinUs{
        .JoinBox h4 {
            display: block;
            text-align: center;
            font-size: 40px;
        }
        .JoinBox p {            
            text-align: center;
            font-size: 21px;
        }
        .txt-center{
            text-align: center;
        }
        
    }
}
@media(max-width:414px){
    .JoinUs{
        .JoinBox h4 {
            font-size: 30px;
        }
    }
}