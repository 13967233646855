.EditProfilePage {
    
    label {
        margin-bottom: 18px;
    }
    .ProfileBadge {
        width: 100px;
        background: #F5F5F5;
        aspect-ratio: 1;
        display: flex;
        border-radius: 50%;
        position: relative;
        align-items: center;
        justify-content: center;
    }
    .editIcon {
        position: absolute;
        bottom: 8px;
        right: 6px;
        background: #fff;
        border-radius: 50%;
        padding: 2px 4px;
    }
    .ProfileBadge span {
        font-size: 30px;
    }
    .ProfileMeta p {
        margin-bottom: 4px;
        font-size: 16px;
    }
    .form-control {
        border-color: rgba(112, 112, 112, 0.2);
        margin: 12px 0;
    }
    .view-btn {
        color: #1CAF00;
        margin-right: 20px;
    }
    .edit-btn {
        color: #000;
        font-weight: 600;
    }
    .view-btn {
        color: #079e43;
        margin-right: 20px;
        font-weight: 600;
    }
    .divider {
        background: #707070;
        display: inline-block;
        width: 100%;
        height: 1px;
        margin-bottom: 30px;
        margin-top: 10px;
        opacity: 0.2;
    }
    .linkimg {
        width: 20px;
    }
    .addMoreBtn {
        color: #079e43;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
    }
    .UploadFileInput .loader {
        float: right;
    }
    .UploadFileInput .loader svg {
        width: 20px;
        position: relative;
        top: -6px;
    }
    .AttachItem {
        background: #fff;
        margin-bottom: 15px;
        border-radius: 5px;
        padding: 12px;
        box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.12);
        grid-gap: 10px;
        display: flex;
        align-items: center;
    }
    .AttachItem .titleSide {
        flex: 1;
        overflow-wrap: anywhere;
    }
    .AttachItem .titleSide p {
        font-size: 12px;
        line-height: 16px;
        opacity: 0.7;
    }
    .ActionBtns a {
        margin-right: 5px;
        font-size: 12px;
        background: #468be0;
        color: #fff;
        padding: 4px 5px;
        border-radius: 4px;
    }
    .ActionBtns button {
        background: transparent;
        border: none;
        font-size: 12px;
        color: red;
    }
    .CusLabel {
        display: flex;
        flex-direction: row;
        grid-gap: 12px;
        align-items: center;
        margin-bottom: 20px;
    }
    .CusLabel label {
        margin: 0;
    }
    .StarRed{
        color: #FF0000;
    }    
}
@media(max-width:1024px){
    .EditProfilePage {
        h3 {
            font-size: 20px;
        }        
        .form-control {
            margin: 7px 0;
            height: auto;
        }
    }
}
@media(max-width:768px){
    .EditProfilePage {
        label {
            font-size: 12px;
            margin-bottom: 10px;
        }
        .ProfileBadge {
            width: auto;
        }
        .ProfileBadge span {
            font-size: 20px;
        }
        .editIcon {
            bottom: -10px;
            right: 0px;
        }
        
    }
}
@media(max-width:767px){
    .EditProfilePage {
        padding-bottom: 20px;
        .mb-5 {
            margin: 4px 0!important;
        }
        .form-control {
            margin: 7px 0;
        }
        .ProfileBadge {
            width: 60px;
        }
    }

}
