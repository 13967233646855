.VerifyProfilePage3 {
    .verifyProgress {
        display: inline-block;
        margin-top: 30px;
        margin-bottom: 30px;
        position: relative;
    }
    .verifyProgress .sec {
        display: inline-block;
        margin: 0 30px;
        text-align: center;
    }
    .dot {
        width: 7px;
        height: 7px;
        background: #94B1AB;
        border-radius: 100px;
        display: inline-block;
        border: 22px solid #fff;
        box-sizing: unset;
    }
    .sec.active .dot {
        border-color: #C6E4DE;
    }
    .verifyProgress .sec.active {
        opacity: 1;
    }
    .verifyProgress::before {
        content: "";
        position: absolute;
        top: 24px;
        left: 15%;
        width: 70%;
        height: 3px;
        background: #c7e4dd;
        z-index: -1;
    }
    .sec p {
        opacity: 0.4;        
    }
    .active.sec p {
        opacity: 1;
    }
    .uploadpicture {
        display: flex;
        align-items: center;
        grid-gap: 20px;
    }
    .uploadpicture p {
        opacity: 0.45;
        line-height: 24px;
        margin-bottom: 0;
    }
    label {
        margin-bottom: 18px;
    }
}