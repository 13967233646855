@keyframes fa-spin{
    0% {
        -webkit-transform: rotate(0deg);
        transform:rotate(0deg)
    }
    100% {
        -webkit-transform:rotate(359deg);
        transform:rotate(359deg)
    }
}
.btn-primary[disabled]:after, .btn-success[disabled]:after, .btn-info[disabled]:after, .logoutBtn[disabled]:after {
    content: "\f110";
    font: normal normal normal 14px/1 FontAwesome;
    margin-left: 10px;
    animation: fa-spin 2s infinite linear;
    transition: all 0.3s ease;
    display: inline-block;
}
.SelectLoader{
    animation: fa-spin 2s infinite linear;
    transition: all 0.3s ease;
    display: inline-block;
    position: absolute;
    top: 17px;
    right: 35px;
}
