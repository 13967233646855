.HomeNoteItem {
    overflow: hidden;
    border-radius: 10px;
    display: inline-block;
    color: #000;
}
.HomeNoteItem:hover, .HomeNoteItem:focus {
    text-decoration: none;
    color: #000;
}
.HomeNoteItem {
    .NoteItemUp {
        display: flex;
    }
    .cover {
        flex: 1 1;
        overflow: hidden;
        border-radius: 12px;
        position: relative;
        border: 2px solid #C6E4DE;
        height: 210px;
    }
    .cover img {
        position: absolute;
        max-width: 120%;
        min-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .authorMeta {
        flex: 1;
        overflow: hidden;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
    }
    .universityImg {
        max-width: 100%;
        margin-bottom: 6px;
    }
    .AuthorImg {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 6px;
        position: relative;
    }
    .AuthorImg img {
        min-width: 100%;
        max-width: 120%;
        min-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .authorMeta span {
        font-size: 16px;
        opacity: 0.6;
    }
    .authorMeta p {
        font-size: 18px;
    }
    .NoteItemBtm {
        margin-top: 20px;
        display: flex;
        align-items: center;
    }
    .NoteName {
        flex: 1;
        padding-right: 14px;
    }
    .NoteAction {
        font-size: 14px;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
    }
    .NoteAction span {
        margin-left: 5px;
        font-size: 20px;
        vertical-align: text-bottom;
    }  
    .owl-stage-outer {
        z-index: 999;
        padding: 10px;
    }  
}
.paper {
    background: #fff;        
    position: relative;       
    padding: 15px;
    border-radius: 10px;
  }  
  .paper, .paper::before, .paper::after { 
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, .2);
  }  
  .paper::before{ content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
  }
  .paper::after { content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
  }
  .paper::before {
    left: 8px;
    top: 5px;
    z-index: -1;
  }
  .paper::after {
    left: 15px;
    top: 10px;
    z-index: -2;
  }