.siteFooter {
    background-size: cover;
    padding: 80px 0px 0px;
}

.siteFooter {
    .footStrip {
        background: #000000;
        color: #fff;
        padding: 7px 0;
        width: 100%;
    }
    a {
        color: #000;
        display: block;
        line-height: 26px;
    }
    .SocialLinks {
        display: flex;
        align-items: center;       
        grid-gap: 24px;
        margin-top: 14px;
        margin-bottom: 100px;
    }
    .SocialLinksTwo {
        display: flex;
        align-items: center;
        grid-gap: 24px;
        background: #fffef5;
        padding: 5px;
        display: none;
    }
    strong {
        margin-bottom: 12px;
        display: inline-block;
    }
    .newsForm {
        display: flex;
    }
    .newsForm input[type="text"] {
        flex: 1;
        border-radius: 4px;
        margin-right: 12px;
        border: 1px solid #373737;
        background: transparent;
        height: auto;
        padding: 10px 12px;
    }   
    .footStrip a {
        color: #fff;
        display: inline;
    }
    .icon-width{
        width: 26px;
    }
    .footerPadd{
        padding-bottom: 80px;
    } 
}
.PlaylistPopup {
    .CreateNewBtn {
        border: none;
        width: 100%;
        padding: 12px 0;
        margin-bottom: -12px;
        background: transparent;
        border-top: 1px solid #ccc;
    }
    .playlistItems {
        display: inline-block;
        max-height: 240px;
        overflow-y: auto;
        width: 100%;
        margin-bottom: -6px;
    }
    .itmm {
        font-size: 16px;
        padding: 10px 10px;
        cursor: pointer;
    }
    .itmm:hover {
        background: rgba(0,0,0,0.05);
    }
    .modal-content {
        border-radius: 0px;
    }
    .createNewPlaylist {
        padding: 0 30px;
        border-top: 1px solid #eee;
        padding-top: 10px;
    }    
    .modal-dialog.PlaylistPopup {
        max-width: 400px;
    }
    .icon-width{
        width: 26px;
    } 
}
@media(max-width:1199px){
    .siteFooter { 
        padding: 20px 0px 0px;
    }
}

@media(max-width:767px){
    .siteFooter {        
        .SocialLinks a {            
            line-height: 26px;
        }
        strong {
            margin: 10px 0;
            display: block;
        }
        .footStrip {       
            padding: 7px 0px;
            text-align: center;
            margin-top: 10px;
        }
        .SocialLinks {
            display: flex;
            align-items: center;
            grid-gap: 24px;
            margin-top: 14px;
            display: none;
        }
        .mt {
            margin-top: 10px;
        }
        .TextCenter {
            text-align: center;
        }
        .footerPadd {
            padding: 0px 20px 10px;
        }
                
    }
}
@media(max-width:500px){
    .siteFooter {
        .SocialLinksTwo {
            display: block;
            align-items: center;
            grid-gap: 24px;
            background: #fff9d6;
            padding: 6px 5px;
            justify-content: center;
            margin: -8px -15px 10px -15px;
        }
        .footStrip a {
            color: #fff;
            display: inline-block;
            width: auto;
        }
        .SocialLinksTwo a {
            width: 25%;
        }
        .SocialLinksTwo a img {
            width: 17px;
            height: 17px;
        } 
    }
}