.ExploreUniversitySec {
    padding: 50px 0;
}
.ExploreUniversitySec {
    .SliderSc {
        margin-top: 50px;
        margin-bottom: 40px;
    }
    .owl-carousel > .owl-nav.disabled {
        display: flex;
    }
    .owl-stage-outer {
        z-index: 999;
    }
    .owl-nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -70px;
        width: calc(100% + 140px);
        display: flex;
    }
    .owl-nav > div {
        flex: 1;
        text-align: left;
    }
    .owl-nav > .owl-next {
        text-align: right;
    }
    .nav-btn span {
        font-size: 30px;
        transition: all 0.2s ease;
        display: inline-block;
    }
    .nav-btn span:hover {
        transform: scale(1.2);
    }
    .SliderSc .owl-item > a {
        min-height: 140px;
        display: inline-block;
        align-items: center;
        display: flex;
        transition: all .5s ease-in-out;
        overflow: hidden;
        padding:0 10px;
    }
    .SliderSc .owl-item > a:hover{
        transform: scale(1.1);
        background: #fafafa;
    }
    .secndhead span {
        font-size: 17px;
        font-weight: 600;
    }
    .ViewMoreBtn {
        color: #000;
        background: #fed703;
        border-radius: 50px;
        margin: 40px 0 0;
        display: inline-block;
        transition: all .5s ease-in-out;
        text-decoration: none;
    }
    .ViewMoreBtn:hover{
        background:#e6c40a;
        text-decoration: none;
    }
    .SliderSc .owl-item img {
        display: block;            
        height: 70px;
        margin: 0 auto;
        width: auto;
        object-fit: contain;
    }

    
}
@media( max-width:1080px){
    .ExploreUniversitySec{
        .ArrowSet .owl-nav {
            position: absolute;
            top: 45%;
            transform: translateY(-50%);
            left: 0px;
            width: 100%;
            display: flex;
            z-index: 9999;
        }
        .ArrowSet .icon-arrow-left-circle:before {
            content: "\e07a";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0px;
        }
        .ArrowSet .icon-arrow-right-circle:before {
            content: "\e079";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
        }
        .SliderSc .owl-item img {
            display: block;
            width: 50%;
            margin: 0 auto;
        }
    }   
}
@media( max-width:767px){
    .ExploreUniversitySec{
        padding: 20px 0!important;
        .secndhead span {
            font-size: 14px!important;
        }               
        .nav-btn span {
            font-size: 20px!important;
        }        
        .nav-btn span:hover {
            transform: none!important;
        }
    }
}
