.EditNoteStep2{
    .verifyProgress {
        display: inline-block;
        margin-top: 30px;
        margin-bottom: 30px;
        position: relative;
    }
    .verifyProgress .sec {
        display: inline-block;
        margin: 0 30px;
        text-align: center;
    }
    .dot {
        width: 7px;
        height: 7px;
        background: #94B1AB;
        border-radius: 100px;
        display: inline-block;
        border: 22px solid #fff;
        box-sizing: unset;
    }
    .sec.active .dot {
        border-color: #C6E4DE;
    }
    .verifyProgress .sec.active {
        opacity: 1;
    }
    .verifyProgress::before {
        content: "";
        position: absolute;
        top: 24px;
        left: 20%;
        width: 62%;
        height: 3px;
        background: #c7e4dd;
        z-index: -1;
    }
    .sec p {
        opacity: 0.4;
    }
    .active.sec p {
        opacity: 1;
    }
    .coverImg {
        max-width: 100%;
    }
    .contentSec {
        align-self: center;
    }
    .btnSec {
        align-self: center;
    }
    .StepFrm .form-control {
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 8px;
        padding-right: 8px;
    }
    .greencheck {
        font-size: 20px;
        margin-left: 5px;
        color: #5bce06;
    }
}