.SetAvailabilityPage{
    .react-calendar {
        width: 100%;
        height: auto;
    }
    span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
        font-size: 20px;
    }
    .react-calendar__navigation > button {
        font-size: 30px;
        padding: 24px 0;
        height: auto !important;
        display: inline-block;
    }
    .react-calendar__navigation {
        height: auto;
    }
    .react-calendar__viewContainer {
        background: #F5F5F5;
        border-radius: 12px;
        padding: 20px;
    }
    .react-calendar__month-view__days > button {
        height: auto;
        margin: 12px 0;
        padding: 15px 5px;
        border-radius: 5px;
    }
    .react-calendar {
        border: none;
    }
    .react-calendar__month-view__days__day--weekend {
        color: rgba(0,0,0,0.3);
    }
    .react-calendar__tile--now, .react-calendar__tile--now:hover, .react-calendar__tile--now:focus {
        background: #8a989133;
    }
    .react-calendar__tile--active, .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
        background: rgb(35,169,142);
        background: -moz-linear-gradient(230deg, rgba(35,169,142,1) 0%, rgba(86,223,141,1) 100%);
        background: -webkit-linear-gradient(230deg, rgba(35,169,142,1) 0%, rgba(86,223,141,1) 100%);
        background: linear-gradient(230deg, rgba(35,169,142,1) 0%, rgba(86,223,141,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#23a98e",endColorstr="#56df8d",GradientType=1);
        color: #000;
        background: #ffd700;
    }    
    .DayScheduleItem {
        display: flex;
    }
    .contentSide {
        flex: 1;
        align-items: center;
        align-content: center;
        display: flex;
    }
    .emptyLine {
        display: inline-block;
        width: 100%;
        border-top: 10px solid #fff;
        min-height: 1px;
        background: rgba(0,0,0,0.2);
        cursor: pointer;
        border-bottom: 10px solid #fff;
        box-sizing: content-box;
    }
    .timeSide {
        padding: 10px 20px;
    }
    .ScheduleHeader {
        padding: 20px;
        display: flex;
    }
    .ScheduleHeader h4 {
        flex: 1;
        margin-right: 10px
    }
    .ScheduleBody {
        padding-left: 20px;
        padding-right: 20px;
    }
    .DayScItem p {
        background: #C4F1D4;
        display: inline-block;
        padding: 6px 20px;
        border-left: 6px solid #1CAF00;
        border-radius: 6px;
    }
    .DayScItem {
        margin-bottom: 14px;
    }
    .DayScItem.BookedItem p {
        background: #FBE3D1;
        color: #FF6E00;
        border-color: #FF6E00;
    } 
    abbr[title], abbr[data-original-title] {
        text-decoration: underline;
        text-decoration: none;
        cursor: help;
        border-bottom: 0;
        text-decoration-skip-ink: none;
    }
    .DeleteSlotIcon {
        margin-left: 14px;
        width: 25px;
        height: 25px;
        background: #21af04;
        display: inline-block;
        text-align: center;
        padding: 6px 0;
        border-radius: 50%;
        cursor: pointer;
        color: #fff;
    }
    .welcomeBg{
        background: #cef1ea;
        padding: 15px;
        margin-bottom: 30px;
        border-radius: 15px;
    }
    .padd25{
        padding: 25px 10px;
    }
}
.smlabel {
    font-size: 14px;
}
.chip {
    margin-top: 6px;
    margin-right: 6px;
}
.modal-backdrop.show {
    opacity: 0.4;
}
.modal-content {
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.20);
    -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.20);
    border: none;
    border-radius: 12px;
}
.TimeDropDown {
    display: flex;
    align-items: center;
}
.TimeDropDown p {
    margin-bottom: 0;
    margin-left: 12px;
    margin-right: 12px;
}
.TimeDropDown .form-control {
    border: none;
    background: #F5F5F5;
    padding: 8px 7px;
}
.PopCloseIcon {
    display: flex;
    justify-content: flex-end;
}
.PopCloseIcon span {
    font-size: 26px;
    cursor: pointer;
}
@media(max-width:1199px){
    .SetAvailabilityPage {
        .welcomeBg { 
            padding-right: 40px;
        }
    }
}
@media(max-width:1024px){
    .SetAvailabilityPage {
        padding-bottom: 15px;
        .ScheduleHeader {
            padding: 10px;
            display: block;
        }
        
    }
}
@media(max-width:768px){
    .SetAvailabilityPage {
        .welcomeBg { 
            padding-right: 40px;
        }
        .padd25 {
            padding: 7px 10px;
        }
        .ScheduleHeader {
            padding: 3px;
            display: block;
            text-align: right;
        }
        .SetRight {
            width: 120px;
            height: 120px;
        }
        .ScheduleHeader h4 {
            flex: 1 1;
            margin-right: 10px;
            font-size: 16px;
        }
        
    }
}
@media(max-width:767px){
    .SetAvailabilityPage {
        .SetRight {
            width: 50%;
            height: auto;
        }
        .welcomeBg {
            padding-right: 0;
        }
        .padd25 {
            padding: 7px 20px;
        }
        .ScheduleHeader h4 {
            flex: 1 1;
            margin-right: 10px;
            font-size: 16px;
            display: inline;
        }
        .ScheduleBody {
            padding-left: 10px;
        }
    }
}
@media(max-width:500px){
    .SetAvailabilityPage {        
        .ScheduleHeader h4 {
            flex: 1 1;
            margin-right: 60px;
            font-size: 16px;
            display: inline;
        }
        .react-calendar__viewContainer {
            margin-bottom: 10px;
        }
        
    }
}
