.NotificationBellContainer {
    position: relative;
}
.NotificationBellContainer {
    .NotificationBell {
        background: transparent;
        border: none;
    }
    .NotificationListPop {
        position: absolute;
        background: #fff;
        width: 350px;
        height: 400px;
        border-radius: 14px;
        top: 45px;
        z-index: 999;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.13);
        left: -300px;
    }
    .NotificationListPopHeader {
        padding: 14px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.13);
        display: flex;
        align-items: center;
    }
    .NotificationListPopHeader h5 {
        flex: 1;
        margin: 0;
        font-size: 18px;
        font-weight: 600;
    }
    .NotificationListPopHeader img {
        max-width: 16px;
        position: relative;
        cursor: pointer;
    }
    .NotificationItems {
        padding: 10px;
        height: calc(100% - 50px);
        overflow: auto;
    }
    .NotificationItem {
        padding: 10px;
        border-radius: 6px;
        margin-bottom: 10px;
        color: #000;
        display:block;
        
    }
    .NotificationItem:hover {
        background: #f5f5f5;
        text-decoration: none;
    }
    .NotificationItem .upSide {
        display: flex;
        align-items: center;
    }       
    .NotificationItem .upSide p {
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 0;
        margin-right: 10px;
        flex: 1;
    }
    .NotificationItem .btmSide p {
        font-weight: 200;
        line-height: 22px;
        color:#000;
    }
    .NotificationListPop .noResult {
        text-align: center;
        margin-top: 29px;
        display: inline-block;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        opacity: 0.4;
    }
    .NotificationItemIsRead{
        padding: 10px;
        border-radius: 6px;
        margin-bottom: 10px;
        color: #000;
        display:block;
        
        background: #ecd504;
    }
}
@media(max-width:767px){
    .NotificationBellContainer{
        .NotificationListPop {
            position: absolute;
            background: #fff;
            width: auto;
            height: auto;
            border-radius: 14px;
            top: 45px;
            z-index: 999;
            box-shadow: 0 0 6px rgba(0, 0, 0,.1);
            left: 0;
            right: 0;
        }
        .NotificationListPop .noResult {
            text-align: center;
            margin-top: 0;
            display: inline-block;
            width: 100%;
            font-size: 12px;
            font-weight: 600;
            opacity: 0.4;
        }
        .NotificationListPopHeader h5 {            
            font-size: 12px;
            font-weight: 600;
        }
        .NotificationListPopHeader img {
            max-width: 12px;
        }
    }
}