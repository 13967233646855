.VideoCall {
    position: fixed;
    height: 100%;
    width: 100%;
}
.VideoCall {
    
    .UpperSection {
        position: absolute;
        height: calc(100% - 90px);
        width: 100%;
    }
    #frontVideoElem {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #4a4a4a;
    }
    #curUserVidElem {
        position: absolute;
        bottom: 40px;
        height: 120px;
        background: #000;
        left: 40px;
    }
    .ActionBtns {
        position: absolute;
        bottom: 0;
        background: #353535;
        width: 100%;
        height: 90px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .centerSec {
        overflow: hidden;
        display: flex;
        grid-gap: 20px;
        justify-content: center;
        flex: 1;
    }
    .EndCallBtn {
        background: transparent;
        border: none;
        width: 50px;
        padding: 0;
        height: 50px;
    }
    .EndCallBtn img {
        width: 100%;
    }
    .MuteCallBtn {
        width: 50px;
        height: 50px;
        border: none;
        background: rgba(0,0,0,0.4);
        border-radius: 50%;
        color: #fff;
        padding: 0;
    }
    .MuteCallBtn span {
        font-size: 20px;
    }
    .MuteCallBtn.active {
        background: rgba(255,255,255,0.8);
    }
    .MuteCallBtn.active span {
        color: #000
    }
    .ToggleScreenCallBtn {
        width: 50px;
        height: 50px;
        border: none;
        background: rgba(0,0,0,0.4);
        border-radius: 50px;
        color: #fff;
        padding: 0;
    }
    .ToggleScreenCallBtn span {
        font-size: 20px;
    }
    .ToggleScreenCallBtn.active {
        background: rgba(255,255,255,0.8);
    }
    .ToggleScreenCallBtn.active span {
        color: #000
    }
    .StatusText {
        position: absolute;
        bottom: 101px;
        right: 40px;
        background: #696969;
        padding: 4px 12px;
        border-radius: 4px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    }
    .rightSec {
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        padding-right: 20px;
    }

}