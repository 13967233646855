@import "custom";
@import "_icons";

h1, h2, h3, h4, h5, h6, p, strong, span, b, i, input, select, * {
  font-family: "Muli", sans-serif; 
}
h5 {
  font-size: 16px;
}
p {
    line-height:2;
    font-size: 14px;
}
span {
  font-size: 14px;
}
.op-5 {
  opacity: 0.5;
}
label{
  font-size: 16px;
}

.SecTitle {
    color: #000;
    font-weight: bold;
    margin-bottom: 20px;
}
.SecMeta {
    color: #000;
    padding: 0 70px;
}
.pageTitle {
  border-bottom: 1px solid rgba(112,112, 112, 0.13);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.pageTitle h4 {
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
}
.op7 {
  opacity: 0.7;
}
.op4 {
  opacity: 0.4;
}
.fullWidthInput {
  width: 100%;
}
.noResultsFound img {
  max-width: 500px;
  margin-bottom: 20px;
}
.HeaderMargin {
  display: inline-block;
  height: 140px;
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.form-control {
  border-color: rgba(112, 112, 112, 0.5);
  border-radius: 10px;
  padding: 14px 14px;
  height: 60px;
  opacity: 1;
  font-size: 14px;
  color:#000;
}
.form-control[data-style="underline"] {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;
}
.btn.btn-bit-wide {
  padding-left: 40px;
  padding-right: 40px;
}
.LabelRequired {
  color: red
}
.blue-link {
  color: #4183c4
} 

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 10px;
    background: #fff;    
}
  
  .header.header-scrolled {
    background: #fff;
    padding: 15px 0;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  }
  
  .header .logo {
    line-height: 0;
  } 
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  .navbar li {
    position: relative;
  }  
  .navbar > ul > li > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    font-family: "Muli", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #2d2d29;
    white-space: nowrap;
    transition: 0.3s;
    margin-left: 40px;
    border-bottom: 5px solid transparent;
  }  
  .navbar a i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
  }  
  .navbar a:hover, .navbar li:hover > a {
      border-color: #fed703;
    }
  .navbar .active {
      color: #000;
      font-weight: 800;
      border-color: #fed703;
  } 
  .navbar .getstarted {
      background: #fed703;
      padding: 8px 20px;
      margin-left: 50px;
      border-radius: 50px;
      color: #000;
      border-width: 0;
      margin-right: 0px;
  }
  .navbar .getstarted:hover {
      color: #000;
      background: #e6c40a;
      border-color: transparent;
      text-decoration: none;
  }  
  .navbar .dropdown ul {
      display: block;
      position: absolute;
      left: 14px;
      top: calc(100% + 30px);
      margin: 0;
      padding: 10px 0;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      transition: 0.3s;
      border-radius: 4px;
  }  
  .navbar .dropdown ul li {
      min-width: 200px;
  }  
  .navbar .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      font-weight: 600;
  }  
  .navbar .dropdown ul a i {
      font-size: 12px;
  }  
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
      color: #4154f1;
  }  
  .navbar .dropdown:hover > ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
  }  
  .navbar .dropdown .dropdown ul {
      top: 0;
      left: calc(100% - 30px);
      visibility: hidden;
  }  
  .navbar .dropdown .dropdown:hover > ul {
      opacity: 1;
      top: 0;
      left: 100%;
      visibility: visible;
  }
  
  /*----------Mobile Navigation------------*/
  .mobile-nav-toggle {
    color: #012970;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s ease-in-out;
  }  
  .mobile-nav-toggle.bi-x {
    color: #fff;
  } 
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(1, 22, 61, 0.9);
    transition: 0.3s;
  }  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }  
  .navbar-mobile a {
    padding: 10px 20px;
    font-size: 15px;
    color: #012970;
  }  
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #4154f1;
  }  
  .navbar-mobile .getstarted {
    margin: 15px;
  }  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }  
  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #4154f1;
  }  
  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
  .BtnSets{
    font-weight: bold;
  }   
   
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
        left: -100%;
    }
  }
@media (max-width: 1199px) { 
  .header {
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 10px;
    background: #fbfbfb;
  }
}

@media (max-width: 991px) {    
  .mobile-nav-toggle {
        display: block;
        background: #fed703;
        width: 40px;
        height: 40px;
        line-height: 35px;
        text-align: center;
        color: #000;
        font-size: 30px;
        padding: 0;
  }    
  .navbar ul {
      display: block;
      position: fixed;
      top: 0;
      width: 250px;
      right: 0;
      background: #fff9d6;
      height: 100%;
      padding: 20px;
  }
  .navbar > ul > li > a {
      color: #000;
      margin-left: 0
  }
  .navbar .active {
      color: #000;    
      border-color: transparent;
  }
  .navbar{
      display: none;
  }  
  .navbar.active-right{
      display: block;
  }  
  .navbar {
    .dropdown:hover > ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
      background: #fff;
      padding: 0;
      display: table;
    }     
    .getstarted {
      margin-left: 0px;
      border-radius: 0;
      text-align: center!important;
      display: block;
    }
  }
}
@media (max-width:767px){
      .SecMeta {     
        padding: 0px 10px;
        font-size: 14px!important;
      }
      .SecTitle {      
        font-size: 30px;
        margin-bottom: 10px;
      }
    .BannerPage{
      .HeroText{
        font-size: 18px;
        margin-bottom: 10px!important;
      }
      .HeroPara {
        padding: 0 0px!important;
        font-size: 16px!important;
        line-height: 22px;
      }
    }
    .MyPlaylistPage{
      .noResultsFound img {
        width: 100%;
        margin-bottom: 20px;
      }
    } 
}        
 

 