.pdfEditor {
    height: 600px;
}
.pdfEditor {
    .rpv-thumbnail-container {
        width: 100px !important;
        overflow: hidden;
        height: auto !important;
    }
    .rpv-thumbnail-container img {
        width: 100% !important;
        height: auto !important;
    }  
    .MainToolbar {
        display: flex;
    }
    .ToolbarBtns {
        align-items: center;
        display: flex;
        flex: 1
    }
    .ToolbarActionArea {
        margin-left: 20px;
    }
}