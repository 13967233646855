.BecomeAMentee {
    background: #fff9d6;
    padding-top: 60px;
    padding-bottom: 60px;
}

.BecomeAMentee {
    .image{
        max-width: 100%;
    }
    .secndhead p{
        font-weight: 600;
        font-size: 17px;        
    }
    .ImgMobile{
        display: none;
        width: 100%;
    }
}

@media(max-width:1199px){
    .BecomeAMentee{
        padding: 20px 0px;
        .ImgSet {
            width: 100%;
        }
        .BecomeAMentee{
            padding: 20px 10px;
        }
    }
}
@media(max-width:767px){
    .BecomeAMentee{
        .ImgMobile{
            display: block;
        }
        .ImgSet {
            display: none;
        }
    }
}
@media(max-width:414px){
    .BecomeAMentee{
        .secndhead p{                
            font-size: 14px;              
        }
    }
}