.RatingsAndReviewsPage{
    .TopReviewsSec {
        margin-top: 25px;
        padding-top: 25px;
        border-top: 1px solid rgba(112,112,112,0.13);
    }
    .UserImg {
        position: relative;
        width: 100%;
        aspect-ratio: 1;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 20px;
    }
    .UserImg img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 140%;
        min-height: 100%;
        min-width: 100%;
        max-height: 130%;
    }
    .descBox {
        background: #fff;
        border: 1px solid #e0e0e0;
        padding: 10px;
        border-radius: 10px;
        margin-top: 5px;
    }
    strong {
        font-size: 16px;
    }
    .RateItem {
        margin-bottom: 40px;
    }     
}
@media(max-width:768px){
    .RatingsAndReviewsPage{
        .RatingBoxes {
            display: block;
        }
    }
}
@media(max-width:767px){
    .RatingsAndReviewsPage{
        .RatingBoxes {
            display: block!important;
            grid-gap: 10px;
        }
        
    }
}
