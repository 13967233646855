.MyNotesPage {
    .verifyProgress {
        display: inline-block;
        margin-top: 30px;
        margin-bottom: 30px;
        position: relative;
    }
    .verifyProgress .sec {
        display: inline-block;
        margin: 0 30px;
        text-align: center;
    }
    .dot {
        width: 7px;
        height: 7px;
        background: #94B1AB;
        border-radius: 100px;
        display: inline-block;
        border: 22px solid #fff;
        box-sizing: unset;
    }
    .sec.active .dot {
        border-color: #C6E4DE;
    }
    .verifyProgress .sec.active {
        opacity: 1;
    }
    .verifyProgress::before {
        content: "";
        position: absolute;
        top: 24px;
        left: 20%;
        width: 62%;
        height: 3px;
        background: #c7e4dd;
        z-index: -1;
    }
    .sec p {
        opacity: 0.4;
    }
    .active.sec p {
        opacity: 1;
    }

    .uploadedFileContainer {
        width: 120px;
        overflow: hidden;
        height: 130px;
        border: 1px solid #C6E4DE;
        border-radius: 10px;
        position: relative;
        text-align: center;
    }
    .uploadedFileContainer img {
        width: 60%;
        margin-top: 10px;
    }
    .fileOverlay {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(0,0,0,0.6);
        color: #fff;
        padding: 3px 7px;
        display: flex;
        text-align: center
    }
    .fileOverlay span {
        flex: 1;
    }
    .fileOverlay a {
        display: inline-block;
        text-align: center;
        width: 100%;
    }
    .loaderContainer {
        position: relative;
        width: 200px;
        height: 100px;
        margin-top: 30px;
    }
    .spanColor{
        color: #000;
        font-size: 14px;
    }
    .PickCover {
        background: #EFF9F7;
        padding: 15px 25px;
        border-radius: 50px;
        font-weight: 600;
        display: inline-block;
    }
    .upDownload {
        margin-right: 10px;
    }
}
