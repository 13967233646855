.OurMeet {
    background: #FFFFFF;
    padding-top: 60px;
    padding-bottom: 50px;
}

.OurMeet {

    .Max-Width{
        max-width: 100%;
    } 
    .MeetText {
        margin-top: 40px;
    }
    .heddText h4{
        font-size: 50px;        
    }
    .HeddingText h3 {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 50px;
    }
    .HeddingText {
        margin-top: 50px;
    }    
    .HeddingText p {
        font-size: 18px;
        text-align: justify;
        line-height: 30px;
        margin-bottom: 16px;
        font-weight: 300;
    }
    .SocialIcons a{
        font-size: 18px;
        font-weight: bold;
        text-align: right;
        display: block;
        color: #000000;
        transition: all .5s ease-in-out;
    }
    .SocialIcons a:hover{
        text-decoration: none;
    }
    .SocialIcons img {
        margin-right: 12px;
    }
}
@media(max-width:1024px){
    .OurMeet {
        padding:30px 10px;       
    }
}
@media(max-width:768px){
    .OurMeet { 
        .HeddingText {
            margin-top: 0px;
        }
    }
}
@media(max-width:767px){
    .OurMeet { 
        .heddText h4 {
            font-size: 40px;
        }
        .HeddingText h3 {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 24px;
            line-height: 50px;
            text-align: center;
        }
        .SocialIcons {
            text-align: center;
        }
        .SocialIcons a {
            text-align: center;
            margin: 12px 0 0;
        }
        .HeddingText p {
            font-size: 15px;
            text-align: center;
        }
    }
}
@media(max-width:414px){
    .OurMeet { 
        .heddText h4 {
            font-size: 30px;
        } 
        .HeddingText h3 {
            font-size: 20px;
            margin-bottom: 10px;
            line-height: 50px;
        }       
    }
}