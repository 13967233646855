.ExpertItem {
    display: inline-block;
    width: 100%;
    padding: 14px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    position: relative;
    text-align: center;
    color: #000;
    margin-bottom: 30px;
}
.ExpertItem > a:hover {
    color: #000;
    text-decoration: none;
}
.ExpertItem > a {
    display: inline-block;
    width: 100%;
    color: #000;
}
.ExpertItem {
    .ExpertPic {
        width: 124px;
        height: 124px;
        overflow: hidden;
        display: inline-block;
        border-radius: 100px;
        position: relative;
        margin-top: 33px;
    }
    .ExpertPic img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 130%;
        min-width: 100%;
        max-height: 120%;
        min-height: 100%;
    }
    img.UniversityImg {
        max-width: 120px;
        max-height: 64px;
    }
    img.BadgeImg {
        position: absolute;
        right: 0;
        top: 0;
    }
    .MidInfo {
        padding-left: 30px;
        padding-right: 30px;
    }
    .MidInfoIn {
        border-top: 1px solid rgba(198, 228, 222, 0.4);
        border-bottom: 1px solid rgba(198, 228, 222, 0.4);
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 12px 0;
    }
    .tagLine {
        font-size: 14px;
        font-weight: 300;
    }
    .ScoreSec {
        margin-bottom: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 16px;
    }
    .ScoreItem {
        display: flex;
        flex-direction: column;
    }
    .ScoreItem strong {
        font-weight: 400;
        font-size: 20px;
    }
    .ScoreItem small {
        font-size: 14px;
        font-weight: 300;
        opacity: 0.4;
    }
    h4 {
        font-size: 25px;
        margin-bottom: 10px;
    }
    .greenDot {
        position: relative;
        width: 11px;
        height: 11px;
        background: #00FF66;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
        margin-left: 10px;
    }
    .ConnectBtn {
        background: transparent;
        border: 1px solid #000;
        border-radius: 100px;
        padding: 6px 24px;
        color: #000;
    }
    .ConnectBtn:hover {
        text-decoration: none;
    }
    .Rating{
        display: 'flex';
        align-items: 'center';
    }
    .RatingNumber{
        font-size: '65%';
        margin-left: 5;
        font-weight: bold;
        opacity: 0.4;
    }
}