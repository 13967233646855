.BookExpertPage {

    .ExpertPic {
        width: 200px;
        height: 200px;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
    }
    .ExpertPic img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 120%;
        min-width: 100%;
        max-height: 120%;
        min-height: 100%;
    }
    .ExperPictureSide {
        display: flex;
        grid-gap: 30px;
        flex-direction: column;
    }
    .ExpertMeta p {
        margin-bottom: 6px;
        font-size: 16px;
        color: #000;
        text-align: justify;
    }
    .ExpertMeta h3, .ExpertBuySide h3 {
        font-size: 34px;
        margin-bottom: 12px;
    }
    .ExpertMeta p b {
        font-weight: 500;
        color: #000;
    }
    .ExpertBuySide {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
    }
    svg.MuiSvgIcon-root {
        font-size: 26px;
        margin-bottom: 10px;
    }
    .ExpertBuySide p {
        margin-bottom: 6px;
        font-size: 16px;
        color: #000;        
    }
    .ExpertBuySide p b {
        font-weight: 500;
        color: #000;
    }
    .AboutSec {
        background: #F8F8F8;
        margin-top: 40px;
        padding: 30px;
        border-radius: 50px;
    }
    .AboutSec p {
        line-height: 36px;
        font-weight: 300;
        font-size: 15px;
    }
    .QualTable strong {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        display: inline-block;
        color: #000;
    }
    .QualTable p {
        font-size: 16px;
    }

    .react-calendar {
        width: 100%;
        height: auto;
    }
    span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
        font-size: 20px;
    }
    .react-calendar__navigation > button {
        font-size: 30px;
        padding: 24px 0;
        height: auto !important;
        display: inline-block;
    }
    .react-calendar__navigation {
        height: auto;
    }
    .react-calendar__viewContainer {
        background: #F5F5F5;
        border-radius: 12px;
        padding: 20px;
    }
    .react-calendar__month-view__days > button {
        height: auto;
        margin: 7px 0;
        padding: 11px 5px;
        border-radius: 5px;
    }
    .react-calendar {
        border: none;
    }
    .react-calendar__month-view__days__day--weekend {
        color: rgba(0,0,0,0.3);
    }
    .react-calendar__tile--now, .react-calendar__tile--now:hover, .react-calendar__tile--now:focus {
        background: #8a989133;
    }
    .react-calendar__tile--active, .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
        background: rgb(35,169,142);
        background: -moz-linear-gradient(230deg, rgba(35,169,142,1) 0%, rgba(86,223,141,1) 100%);
        background: -webkit-linear-gradient(230deg, rgba(35,169,142,1) 0%, rgba(86,223,141,1) 100%);
        background: linear-gradient(230deg, rgba(35,169,142,1) 0%, rgba(86,223,141,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#23a98e",endColorstr="#56df8d",GradientType=1);
        color: #000;
        background: #ffd700;
    }
    
    .DayScheduleItem {
        display: flex;
    }
    .contentSide {
        flex: 1;
        align-items: center;
        align-content: center;
        display: flex;
    }
    .emptyLine {
        display: inline-block;
        width: 100%;
        border-top: 10px solid #fff;
        min-height: 1px;
        background: rgba(0,0,0,0.2);
        cursor: pointer;
        border-bottom: 10px solid #fff;
        box-sizing: content-box;
    }
    .timeSide {
        padding: 10px 20px;
    }
    .ScheduleHeader {
        padding: 20px;
        display: flex;
    }
    .ScheduleHeader h5 {
        flex: 1;
        margin-right: 10px;  
    }
    .ScheduleBody {
        padding-left: 20px;
        padding-right: 20px;
    }
    .DayScItem p {
        background: #C4F1D4;
        display: inline-block;
        padding: 6px 20px;
        border-left: 6px solid #1CAF00;
        border-radius: 6px;
    }
    .DayScItem {
        margin-bottom: 14px;
        cursor: pointer;
    }
    .DayScItem p {
        transition: all 0.3s ease;
    }
    .DayScItem:hover p {
        box-shadow: 0 3px 12px rgb(195, 234, 209);
    }
    .PriceBox {
        background: #f5f5f5;
        margin-top: 20px;
        padding: 20px;
        border-radius: 10px;
        text-align: right;
    }
    .AmountRow {
        border-top: 1px solid #ddd;
        margin-top: 9px;
        padding-top: 8px;
    }
}