.LastReadNotesPage {
    .openBook{
        width: 150px;
    }
    .BookBg {
        background: #cef1ea;
        padding: 15px;
        margin-bottom: 30px;
        border-radius: 15px;
    }
    .NoteText{
        font-weight: 600;
        font-size: 25px;
        color: #000;
    }
    .paranotes {
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        color: #000;
        padding-right: 180px;
    }
    .padd25{
        padding:30px 0px;
    }
}
@media(max-width:1250px){
    .LastReadNotesPage {    
        .padd25{
            padding:30px 40px;
        }
    }
}
@media(max-width:1024px){
    .LastReadNotesPage { 
        .padd25 {
            padding: 30px 0px 30px 70px;
        }
        .paranotes {
            font-size: 14px;
            line-height: 25px;
            font-weight: 400;
            color: #000;
            padding-right: 10px;
        }
        .BookBg {
            margin: 0 0 15px 0!important;
        }
        
    }
}
@media(max-width:768px){
    .LastReadNotesPage { 
        .padd25 {
            padding: 30px 0px 30px 100px;
        } 
    }
}
@media(max-width:767px){
    .LastReadNotesPage { 
        .padd25 {
            padding: 30px 0px 30px 20px;
        } 
        
    }
}

