.pdfViewer {
    height: 550px;
}
.pdfViewer {
    .rpv-thumbnail-container {
        width: auto !important;
        overflow: hidden;
        height: auto !important;
    }
    .rpv-default-layout-container {
        border: none;
        border-radius: 12px;
        overflow: hidden;
    }
    .rpv-thumbnail-container img {
        width: 100% !important;
        height: auto !important;
    }  
    .MainToolbar {
        display: flex;
    }
    .ToolbarBtns {
        align-items: center;
        display: flex;
        flex: 1
    }
    .ToolbarActionArea {
        margin-left: 20px;
    }
}