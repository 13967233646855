.callAlertPop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popBody {
    background: #fff;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.4);
    width: 500px;
    text-align: center;
}
.popBody h2 {
    font-size: 42px;
}
.acBtns {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
}
.acceptCall {
    flex: 1;
    border: none;
    border-radius: 3px;
    font-size: 17px;
    background: #59ca5c;
    color: #fff;
    padding: 10px 10px;
}
.rejectCall {
    background: red;
    border: none;
    flex: 1;
    border-radius: 4px;
    padding: 12px 10px;
    color: #fff;
    font-size: 16px;
}