.ContactUsPage {
    .cItem {
        display: flex;
        align-items: center;
        grid-gap: 20px;
        margin-bottom: 26px;
    } 
    .contactImg {
        width: 450px;
        margin: 0 auto;
        display: block;
    }
    .hoveColor {
        color: #000
    }
}
@media(max-width:1024px){
    .ContactUsPage {
        .contactImg {
            width: 100%;
        }
        .ContactUs{
            margin-bottom: 20px!important;
        }
        .cItem {
            margin-bottom: 15px;
        }
        .HeaderMargin {
            height: 100px;
        }
    }
}
@media(max-width:767px){
    .ContactUsPage {
        .ContactUs{
            margin-bottom: 20px!important;
        }
        .cItem {
            margin-bottom: 15px;
        }
        .mb-5 {
            margin-bottom: 1rem !important;
        }
    }
}