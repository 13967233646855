.WhatSet {
    background: #FFFFFF;
    padding-top: 60px;
    padding-bottom: 50px;
}
.WhatSet {
    .Max-Width{
        max-width: 100%;
    } 
    .textSet h4{
        margin-bottom: 50px;
        display: block;
        font-size: 50px;
        font-weight: 400;
    }    
    .img-botm img {
        width: 1000px;
        text-align: center;
        margin: 0 auto;
        display: block;
    }
}

@media(max-width:1024px){
    .WhatSet {        
        padding: 10px;        
    }
}
@media(max-width:767px){
    .WhatSet {        
        .textSet h4 {
            margin-bottom: 30px;           
            font-size: 40px;          
        }
    }
}
@media(max-width:414px){
    .WhatSet {        
        .textSet h4 {
            margin-bottom: 30px;           
            font-size: 30px;          
        }
    }
}

