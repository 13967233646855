.MenteeMyMeetingsPage{
    .MeetingBox {
        display: inline-block;
        background: #fff;
        overflow: hidden;
        width: 100%;
        padding: 12px;
        border-radius: 14px;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);
        border-top: 16px solid #ffe266;
        margin-bottom: 30px;
    }
    .UserImg {
        width: 100%;
        aspect-ratio: 1;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
    }
    .UserImg img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 120%;
        min-width: 100%;
        min-height: 100%;
    }
    .MentorName {
        font-size: 20px;
        display: inline-block;
        width: 100%;
        padding-top: 16px;
    }
    p {
        font-size: 14px;
        line-height: 20px;       
    }
    .midinfo p {
        margin-bottom: -1px;
    }
    .midinfo {
        margin-top: 10px;
        margin-bottom: 12px;
    }
    .joinCallBtn {
        float: right;
        color: #1EE26C;
        margin-right: 12px;
    }

    .rate-btn {
        background: #fff9e2;
        border: none;
        display: flex;
        align-items: center;
        color: #c39f11;
        padding: 5px 10px;
        border-radius: 8px;
    }
    .rate-btn img {
        max-width: 28px;
        margin-right: 8px;
    }
    
}

.RateItm {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.RateItm .MuiRating-icon {
    font-size: 22px;
}
.RateItm > label {
    font-size: 14px;
    font-weight: 300;
}
.midline {
    font-size: 16px;
}
.RatingBoxes {
    display: flex;
    grid-gap: 10px;
}
.modal-dialog.RateMeetingsPopup {
    max-width: 800px;
}
.RateMeetingsPopup .modal-body {
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 30px;
    padding-bottom: 30px;
}
@media(max-width:768px){
    .RatingsAndReviewsPage{        
        .RateItm > label {
            font-size: 14px;
            font-weight: 300;
            width: 30%;
        }
        .RateItm {
            display: block;
        }
        .MuiRating-icon {
            font-size: 14px;
        }
    }
}

@media(max-width:767px){
    .RatingsAndReviewsPage{
        .RatingBoxes {
            display: block!important;
            grid-gap: 10px;
        }
        .RateItm > label {
            font-size: 14px;
            font-weight: 300;
            width: 50%;
        }
        .RateItm {
            display: block;
        }
        .MuiRating-icon {
            font-size: 14px;
        }        
    }
}
@media(max-width:767px){
    .MenteeMyMeetingsPage{
        .UserImg {
            width: 40%;
            margin: 0 auto;
        }
        .midinfo {
            text-align: left!important;
        }
        .joinCallBtn {
            float: left; 
        }
    }
}

