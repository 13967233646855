.ThankyouPage {
    text-align: center;
    display: inline-block;
    width: 100%;
    padding-bottom: 80px;
}
.ThankyouPage {
    img {
        margin-bottom: 20px;
    }
    p {
        font-size: 18px;
        font-weight: 300;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}