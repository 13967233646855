.ProfileBadge.pro {
    border-radius: 50%;
    overflow: hidden;
}
.ProfileBadgeContainer {
    position: relative;
}
.ProfileBadgeContainer {
    .ProfileBadge {
        cursor: pointer;
    }
    .ProfileBadge.pro .proimg {
        max-width: 200%;
        min-height: 100%;
        min-width: 100%;
        max-height: 150%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .proimg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        max-width: 120%;
    }
    .DownSide {
        background: #fff;
        box-shadow: 4px 0px 23px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        padding: 8px;
        margin-top: 10px;
        position: absolute;
        top: 110%;
        width: 200px;
        z-index: 9;
    }
    .upTriangle {
        border-left: 10px solid transparent;
        border-bottom: 10px solid #fff;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        display: inline-block;
        position: absolute;
        top: -19px;
        left: 40px;
    }
    .down-itm {
        width: 100%;
        text-align: left;
        border: none;
        background: transparent;
        font-size: 14px;
        margin-bottom: 4px;
        padding: 6px;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        grid-gap: 6px;
    }
    .loader svg {
        width: 14px;
        height: 14px;
    }
    .down-itm:last-child {
        margin: 0;
    }
    .down-itm:hover {
        background: #f5f5f5;
    }
    .ProfileBadge {
        width: 100px;
        background: #F5F5F5;
        aspect-ratio: 1;
        display: flex;
        border-radius: 50%;
        position: relative;
        align-items: center;
        justify-content: center;
    }
}