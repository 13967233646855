.ExpertsDetailPage {

    .ExpertPic {
        width: 200px;
        height: 200px;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
    }
    .ExpertPic img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 120%;
        min-width: 100%;
        max-height: 120%;
        min-height: 100%;
    }
    .ExperPictureSide {
        display: flex;
        grid-gap: 30px;
        align-items: center;
    }
    .ExpertMeta p {
        margin-bottom: 6px;
        font-size: 16px;
        color: #676767;
        opacity: 0.8;
    }
    .ExpertMeta h3, .ExpertBuySide h3 {
        font-size: 34px;
        margin-bottom: 12px;
    }
    .ExpertMeta p b {
        font-weight: 500;
        color: #000;
    }
    .ExpertBuySide {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
    }
    svg.MuiSvgIcon-root {
        font-size: 26px;
        margin-bottom: 10px;
    }
    .ExpertBuySide p {
        margin-bottom: 6px;
        font-size: 16px;
        color: #676767;
        opacity: 0.8;
    }
    .ExpertBuySide p b {
        font-weight: 500;
        color: #000;
    }
    .AboutSec {
        background: #F8F8F8;
        margin-top: 40px;
        padding: 30px;
        border-radius: 50px;
    }
    .AboutSec p {
        line-height: 36px;
        font-weight: 300;
        font-size: 15px;
    }
    .QualTable strong {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        display: inline-block;
        color: #000;
    }
    .QualTable p {
        font-size: 16px;
    }
}