.ExploreUniversitySec {
    padding: 50px 0;
}
.ExploreUniversitySec {
    .SliderSc {
        margin-top: 50px;
        margin-bottom: 40px;
    }
    .owl-carousel > .owl-nav.disabled {
        display: flex;
    }
    .owl-stage-outer {
        z-index: 999;
    }
    .owl-nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -70px;
        width: calc(100% + 140px);
        display: flex;
    }
    .owl-nav > div {
        flex: 1;
        text-align: left;
    }
    .owl-nav > .owl-next {
        text-align: right;
    }
    .nav-btn span {
        font-size: 30px;
        transition: all 0.2s ease;
        display: inline-block;
    }
    .nav-btn span:hover {
        transform: scale(1.2);
    }
    .SliderSc .owl-item > a {
        min-height: 140px;
        display: inline-block;
        align-items: center;
        display: flex;
    }
    .CurriculumList {
        margin: 40px auto 0px;
        background: rgba(255,243,177,0.52);        
        display: flex;
        flex-direction: row;
        padding: 0px 26px;
        border-radius: 100px;       
    }    
    .fItem.active {
        background: #FFD700;
        font-weight: bold;
    }
    .fItem {
        padding: 10px 20px;
        border-radius: 100px;
        cursor: pointer;
        font-weight: 500;
        float: left;
    }
    .Flabel {
        display: flex;
        align-items: center;
        margin-right: 50px;
    }
    .secndhead span{
        font-size: 17px;
        font-weight: 600;
    } 
    .SliderSc .owl-stage-outer {
        padding: 20px 0;
    }   
    .WidthSet .owl-carousel.owl-drag .owl-item {
        -webkit-user-select: none;
        user-select: none;
        padding: 0 20px;
    }
}

@media (max-width: 1024px){
    .ExploreUniversitySec{
        .WidthSet .owl-nav {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
            display: flex;
            z-index: 999999;
        }
        .CurriculumList {
            margin: 20px auto 0px;
            background: rgba(255,243,177,0.52);        
            display: block;
            flex-direction: row;
            padding: 0px 10px;
            border-radius: 100px;
            width: auto;
        }
        .fItem {
            padding: 5px 5px;
            border-radius: 100px;
            cursor: pointer;
            font-weight: 500;
            float: left;
            margin-right: 5px;
        }        
   }

}

@media (max-width: 767px){
    .ExploreUniversitySec{
        .WidthSet p {
            text-align: center;
        }
        .nav-btn.prev-slide {
            position: absolute;
            left: 0px;
        }
        .SliderSc {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}
@media (max-width: 414px){
    .ExploreUniversitySec{
        .fItem {
            padding: 5px 8px;            
            margin-right: 2px;
            font-size: 13px;
        }
    }    
}
@media (max-width: 360px){
    .ExploreUniversitySec{
        .fItem {
            padding: 3px 4px;
            margin-right: 2px;
            font-size: 12px;
        }
    }
}