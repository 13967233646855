.Dashbody {
    .LeftPanel {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        overflow-y: auto;
        width: 250px;
        background-size: cover;
        transition: all 450ms ease;
    }
    .LeftHeader {
        width: 100%;
        text-align: center;
        padding: 10px;
        margin-bottom: 20px;
    }
    .navbar, .navbar > ul {
        align-items: flex-start;
    }
    .NavMenu {
        padding-left: 20px;
    }
    .NavMenu li {
        list-style: none;
    }
    .NavMenu li a img {
        margin-right: 18px;
        width: 15px;
    }
    .NavMenu li a:active, .NavMenu li a:hover, .NavMenu li a:focus, .NavMenu li a.active {      
        background: #fff;
        text-decoration: none;
    }
    .NavMenu li a.active.hasDropdown {
        background: rgba(255,255,255,0.05);
    }
    .NavMenu li a {
        margin-bottom: 10px;
        display: inline-block;
        width: 100%;
        padding: 11px 20px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        color: #000;
        font-weight: bold;                                                                                                                                                                                                                    
    }
    .bellIco {
        margin-top: 4px;
        margin-right: 20px;
    }
    .navbar .dropdown ul a {
        color: #000;
        display: inline-block;
    }
    .RightArea {
        position: absolute;
        top: 0;
        left: 250px;
        height: 100%;
        width: calc(100% - 250px);
        overflow-y: auto;
        overflow-x: hidden;
        transition: all 450ms ease;
    }
    .RightHeader {
        display: flex;
        padding: 20px;
    }
    .RightBody {
        padding: 0 50px;
        padding-bottom: 50px;
    }
    .UpperMenu {
        flex: 1 1;
        display: flex;
        justify-content: center;
        grid-gap: 30px;
    }    
    .UpperMenu > a {
        color: #000;
        margin: 0 30px;        
        padding: 10px 0px;
        font-weight: 700;
        font-size: 16px;
        white-space: nowrap;
        transition: .3s;
        border-bottom: 5px solid transparent;
        text-decoration: none;
        transition: all .5s ease-in-out;       
    } 
    .UpperMenu a:hover{
        border-color: #fed703;
    }
    .RightUserPanel {
        margin-left: 20px;
        display: flex;
    }
    .userImg {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50px;
        position: relative;
    }
    .userImg img {
        position: absolute;
        left: 0;
        top: 0;
        min-width: 100%;
        min-height: 100%;
        max-width: 150%;
        max-height: 150%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .userInfo {
        display: flex;
    }
    .infoSec {
        display: flex;
        flex-direction: column;
        margin-left: 14px;
    }
    .RightUserPanel > .icon {
        font-size: 22px;
        margin-right: 30px;
        margin-top: 9px;
    }
    .infoSec > h5 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0px;
        text-transform: capitalize;
    }
    .infoSec > span {
        opacity: 0.5;
        font-size: 14px;
    }
    .infoSec > a {
        font-size: 12px;
        color: #000;
        text-decoration: underline;
        font-weight: 600;
    }
    .nameAlpha {
        width: 100%;
        height: 100%;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        background: #fed703;
    }
    .subMenu {
        max-height: 0px;
        overflow: hidden;
        }
    .subMenu.active {
        max-height: 200px;
    }
    .NavNavis {
        display: none;
    }
    .BaarNav {
        height: 30px;
        width: 30px;
        background: #fed703;
        border: none;
        box-shadow: 1px 2px 6px rgba(199, 199, 199,.8);
        margin:15px 0px;
        display: none;       
    }
    // .BlackLogos{
    //     display: none;
    // }
    .LogoHead{
        display: none;
    }
    // .UserNone{
    //     display: none!important;
    // } 
    .RightUserPanel.UserNone {
        display: none;
    }
    .navbar12 .dropdown ul {
        display: block;
        position: absolute;
        left: 14px;
        top: calc(100% + 30px);
        margin: 0;
        padding: 10px 0;
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        background: #fff;
        box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
        transition: 0.3s;
        border-radius: 4px;
    } 
    .navbar12 li {
        position: relative;
    } 
    .navbar12 .dropdown ul li {
        min-width: 200px;
    }  
    .navbar12 .dropdown ul a {
        padding: 10px 20px;
        font-size: 15px;
        text-transform: none;
        font-weight: 600;
    }   
    .navbar12 .dropdown ul a {
        padding: 10px 20px;
        font-size: 15px;
        text-transform: none;
        font-weight: 600;
    }
    .navbar12 .dropdown ul a {
        color: #000;
        display: block;
    }
}
@media(max-width:1250px){
    .Dashbody {
         .UpperMenu > a {
            color: #000;
            margin: 0 10px!important;
            font-weight: 600!important;
            font-size: 14px!important;
        }
        .RightBody {
            padding: 0 20px!important;
        }
        .bellIco {
            margin-right: 5px!important;
        }
    }
}
@media(max-width:1024px){
    .Dashbody {
        .UpperMenu > a {
            margin: 0 3px!important;
            padding: 10px 0px;
            font-weight: 600!important;
            font-size: 14px!important;
        }
        .RightBody {
            padding: 0 10px!important;
        }
        .statsInBox > span { 
            font-size: 15px!important;
        }
    }
}

@media(max-width:991px){
    .Dashbody {
        
        .UpperMenu > a {
            margin: 0 3px!important;
            padding: 10px 0px;
            font-weight: 600!important;
            font-size: 14px!important;
        }
        .UpperMenu {
            flex: 0;
        }
    }
}
@media(max-width:767px){
    .Dashbody {
        .navbar12 .dropdown:hover > ul {
            opacity: 1;
            top: 100%;
            visibility: visible;
            background: #fff;
            padding: 0;
            display: table;
        }
        .UpperMenu{
            display: none!important; 
        }
        .NavNavis {
            display: block;
        } 
        .RightUserPanel.UserNone {
            display: block;
        }        
        .BaarNav {
            height: 30px;
            width: 30px;
            background: #fed703;
            border: none;
            box-shadow: 1px 2px 6px rgba(199, 199, 199,.8);
            margin: 15px 15px 15px 0px;
            display: block;
            position: absolute;
            right: 0;       
        }
        .Cross {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 20px;
            background: #ecd764;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 4px;
            color: #000;
        }
        .LeftPanel { 
            overflow-x: hidden;
        }
        .RightArea {
            left:0px !important; 
            width:100% !important; 
        }
        .statsBoxes {
            display: block !important;
        }
        .statsBox {
            margin-bottom: 15px;
        }
        // .RightArea.active-right {
        //     left:0px !important; 
        //     width:100% !important;            
        // }
        .BlackLogo {
            display: none;
        }
        .leftNon{
            display: none!important;
        }
        .bellIco {
            margin-top: 0px;
            margin-right: -10px;
        }
        .Logo{
            display:none;
        }
        .LeftHeader {
            padding: 5px!important;
            margin-bottom: 0px!important;
        }
        .LogoHead {
            display: block!important;
            width: auto!important;
            margin: 10px 0!important;
        }       
        .UserBlock{
            display: none!important;
        }
        .userImg {
            width: 30px;
            height: 30px;
            display: block;
        }
        .bellIco {
            margin-top: 0px!important;
            margin-right: 0px!important;
        }
        .Dashbody .userImg {
            display: inline-block;
        }
        .NotificationBell {
            float: left;
        }
        .NotificationListPop {
            position: absolute;
            width: auto;
            height: auto;
            border-radius: 14px;
            top: 45px;
            left: 0;
        }
        .NotificationListPopHeader img {
            max-width: 10px;
            position: relative;
            cursor: pointer;
        }
        .NotificationListPopHeader h5 {
            flex:0;
            margin: 0;
            font-size: 12px;
            font-weight: 600;
            padding-right: 45px;
        }        
        .userInfo {
            display: block!important;
        }
        .infoSec {
            display: inline-block!important;
            flex-direction: column;
            margin-left: 14px;
        }
        .infoSec > h5 {
            font-size: 14px!important;
            margin-right: 5px;
            display: inline-block;
        }
        .infoSec > a {
            display: block;
        }
        .infoSec > span {
            opacity: 1;
            font-size: 14px;
            display: inline-block;
        }
        
    }
}
@media(max-width:500px){
    .Dashbody {
        .statsInBox > span { 
            font-size: 14px!important;
        }
        .RightHeader {
            display: flex;
            padding: 0 10px 10px;
        }
        .LogoHead {
            width: 110px!important;
            margin: 10px 0 !important;
        }
        
    }
}
@media(max-width:360px){
    .Dashbody {
        .statsInBox > span { 
            font-size: 11px!important;
        }
        
    }
}
