.ForgotPasswordSec {
    .CoverArea {
        position: fixed;
        height: 100%;
        width: 50%;
        align-items: center;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        padding: 0;
    }
    .MainArea {
        left: 50%;
        display: flex;
        flex-direction: column;
    }
    .LogoCircle {
        transform: translateX(50%);
    }
    .FormTitle {
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 26px;
    }
    .Form {
        align-items: center;
        display: flex;
        padding-left: 100px;
        padding-right: 100px;
        flex-wrap: wrap;
        justify-content: center;
        height: 100%;
    }
    .FormBody {
        width: 400px;
    }
    label {
        margin-bottom: 14px;
        margin-top: 8px;
    }
    .form-control {
        border-radius: 9px;
        padding: 8px 20px;
        height: auto;
    }
    .btm-link {
        color: #1492E6
    }
    .UpperMenu {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 22px;
        position: relative;
        z-index: 999
    }
    .UpperMenu > a {
        color: #000;
        margin: 0 30px;
    }
}