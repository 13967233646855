.ChooseYourPlanSec {
    background: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
}
.ChooseYourPlanSec {
    .planImg {
        max-width: 100%;
        align-self: center;
    }
    .Choose p{
        font-weight: 600;
        font-size: 17px;
    }
    .PlanBox {
        height: 100%;
        padding: 20px;
        border-radius: 12px;
    }
    .PlanBox strong {
        font-size: 38px;
        font-weight: 600;
    }
    .PlanBox strong span {
        font-weight: 500;
    }
    .PlanBox > p {
        font-size: 20px;
        margin-top: 10px;
        min-height: 100px;
        margin-bottom: 30px;
    }
    .w-btn {
        background: #fff;
        border: none;
        border-radius: 100px;
        padding: 7px 24px;
        font-weight: 500;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        color: #000;
        text-decoration: none;
        transition: all .5s ease-in-out;
    }
    .w-btn:hover{
        background: #fed703;
        color: #000;
    }
    .PadRight {
        padding-right: 40px;
    }
    .PadLeft {
        padding-left: 40px;
    }
    .PlanBox.selected {
        transform: scale(1.12);
        margin-top: -9px;
    }
    .PlanRow {
        margin-top: 66px;
    }

}
@media(max-width:1199px){
    .ChooseYourPlanSec {         
        padding:20px 0px;        
    } 
}
@media(max-width:991px){
    .ChooseYourPlanSec{     
        .PlanBox strong {
            font-size: 20px;           
        } 
        .PlanBox > p {
            font-size: 14px;
            margin-top: 20px;
            min-height: 84px;
            margin-bottom: 15px;
        }       
    }
}
@media(max-width:767px){
    .ChooseYourPlanSec {         
        padding-top: 20px;
        padding-bottom: 10px;
    }
    .ChooseYourPlanSec {
        .PlanRow {
            margin-top: 30px;
        }
        .PlanBox.selected {
            transform: none;
            margin-top: 0px;
        }
        .PadRight {
            padding-right: 15px;
        }
        .PadLeft {
            padding-left: 15px;
        }
        .PlanBox {       
            padding: 20px
        }
        .PlanBox strong {
            font-size: 25px;           
        } 
        .PlanBox > p {
            font-size: 14px;
            margin-top: 20px;
            min-height: auto;
            margin-bottom: 25px;
        }
        .Mb{
        margin-bottom: 15px; 
        }    
    }
}
@media (max-width: 414px){
    .ChooseYourPlanSec {    
        .PlanBox {
            padding: 10px 10px 20px;
        }
    }
}