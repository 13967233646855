.MentorshipListPage{

    .MeetingBox {
        display: inline-block;
        background: #fff;
        overflow: hidden;
        width: 100%;
        padding: 12px;
        border-radius: 14px;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);
        border-top: 16px solid #ffe266;
        margin-bottom: 30px;
    }
    .UserImg {
        width: 100%;
        aspect-ratio: 1;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
    }
    .UserImg img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 120%;
        min-width: 100%;
        min-height: 100%;
    }
    .MentorName {
        font-size: 20px;
        display: inline-block;
        width: 100%;
        padding-top: 16px;
    }
    p {
        font-size: 14px;
        line-height: 20px;
    }
    .midinfo p {
        margin-bottom: -1px;
    }
    .midinfo {
        margin-top: 10px;
        margin-bottom: 12px;
    }
    .joinCallBtn {
        float: right;
        color: #079e43;
        margin-right: 12px;
    }
    .ActionBtns {
        display: flex;
        grid-gap: 5px;
        align-items: center;
        justify-content: flex-end;
    }
    .ActionBtns button {
        background: transparent;
        border: none;
        padding: 4px 8px;
    }
    .ActionBtns .SuccessBtn {
        color: #079e43;
    }
    .viewBtn {
        background: transparent;
        border: none;
        padding: 4px 3px;
        color: #000;
    }

}
@media(max-width:767px){
    .MentorshipListPage{
        .UserImg img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            min-width: auto;
            min-height: auto;
            border-radius: 100%;
        }
        .UserImg {
            width: 50%;
            aspect-ratio: 1;
            overflow: hidden;
            border-radius: 50%;
            position: relative;
            margin: 0 auto;
        }
    }
}