.ExpertsListPage {

    .FilterSide {
        border-right: 1px solid #C9C0C0;
        padding-right: 40px;
        padding-left: 40px;
        border-bottom: 1px solid #C9C0C0;
        height: 760px;
    }
    .FilterBox{
        h5 {
            font-size: 20px;
            margin-top: 20px;
            margin-bottom: 24px;
        }
        input.form-control {
            border-radius: 100px;
            height: auto;
            padding: 10px 20px;
            margin-bottom: 20px;
        }
        .FilterItems {
            max-height: 220px;
            overflow: auto;
            min-height: 120px;
        }
        .FilterItem {
            display: flex;
            align-items: center;
            padding-right: 20px;
        }
        .FilterItem .f_title {
            flex: 1;
        }
        .underline {
            border-bottom: 3px solid #fed703;
            display: inline-block;
        }
    }
    .expertsGrid {
        display: flex;
        flex-direction: column;
        min-height: 700px;
        padding-left: 30px;
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #ffe266;
    }
    .FilterBox{
        position: relative;
    }
    .BgHalf {
        display: inline-block;
        background: #fed703;
        height: 11px;
        width: 60px;
        position: absolute;
        top: 12px;
        left: 1px;
        z-index: -9;
    }
    .BgHalf2 {
        display: inline-block;
        background: #fed703;
        height: 10px;
        width: 60px;
        position: absolute;
        top: 13px;
        left: 2px;
        z-index: -9;
    }
    
    
}