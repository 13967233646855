.BecomeAMenteere {
    background: #fff9d6;
    padding-top: 60px;
    padding-bottom: 60px;
}

.BecomeAMenteere {
    .image{
        max-width: 100%;
    }
    .secndhead p{
        font-size: 17px;
        font-weight: 600;
    }
    .ImgSet {      
        width: 100%;
    }
    .ImgMobile{
        width: 100%;
        display: none;
        
    }

}
@media(max-width:1199px){    
    .BecomeAMenteere{
        padding: 20px 0px;
    }
}
@media(max-width:767px){    
    .BecomeAMenteere{
        .ImgMobile{
            display: block;
        }
        .ImgSet {
            display: none;
        }
    }
}
@media(max-width:414px){  
    .BecomeAMenteere{
        .secndhead p{
            font-size: 14px;           
        }
    }
}