.VerifyProfilePage {
    .verifyProgress {
        display: inline-block;
        margin-top: 30px;
        margin-bottom: 30px;
        position: relative;
    }
    .verifyProgress .sec {
        display: inline-block;
        margin: 0 30px;
        text-align: center;
    }
    .dot {
        width: 7px;
        height: 7px;
        background: #94B1AB;
        border-radius: 100px;
        display: inline-block;
        border: 22px solid #fff;
        box-sizing: unset;
    }
    .sec.active .dot {
        border-color: #C6E4DE;
    }
    .verifyProgress .sec.active {
        opacity: 1;
    }
    .rightInfo {
        padding-left: 10px;
    }
    .verifyProgress::before {
        content: "";
        position: absolute;
        top: 24px;
        left: 15%;
        width: 70%;
        height: 3px;
        background: #c7e4dd;
        z-index: -1;
    }
    .sec p {        
        opacity: 0.4;
    }
    .active.sec p {
        opacity: 1;
    }
    .uploadpicture {
        display: flex;
        align-items: center;
        grid-gap: 20px;
    }
    .uploadpicture p {
        opacity: 0.45;
        line-height: 24px;
        margin-bottom: 0;
    }
    label {
        margin-bottom: 18px;
    }
    .UploadFileInput .loader {
        float: right;
    }
    .UploadFileInput .loader svg {
        width: 20px;
        position: relative;
        top: -6px;
    }
    .AttachItem {
        background: #fff;
        margin-bottom: 15px;
        border-radius: 5px;
        padding: 12px;
        box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.12);
        grid-gap: 10px;
        display: flex;
        align-items: center;
    }
    .AttachItem .titleSide {
        flex: 1;
        overflow-wrap: anywhere;
    }
    .AttachItem .titleSide p {
        font-size: 12px;
        line-height: 16px;
        opacity: 0.7;
    }
    .ActionBtns a {
        margin-right: 5px;
        font-size: 12px;
        background: #468be0;
        color: #fff;
        padding: 4px 5px;
        border-radius: 4px;
    }
    .ActionBtns button {
        background: transparent;
        border: none;
        font-size: 12px;
        color: red;
    }
    .linkdinIcon{
        width: 20px;
    }
}