.DashboardPage {
    a:hover {        
        text-decoration: none;
    }
    .statsBoxes {
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        margin-bottom: 60px;
        padding: 0 8px;
        grid-gap: 20px;
    }
    .statsBox {
        flex: 1;
    }
    .statsInBox {
        background: #fff;
        box-shadow: 0 20px 80px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
        padding: 9px 20px;
        position: relative;
    }
    .statsInBox p {
        font-size: 55px;
        line-height: 55px;
        margin-bottom: 6px;
    }
    .statsInBox > span {
        color: #000;
        font-weight: 400;
        letter-spacing: 0.3px;
        font-size: 14px;
    }
    .arrow {
        background: #FFD700;
        position: absolute;
        padding: 35px 8px;
        top: 50%;
        transform: translateY(-50%);
        right: -9px;
        border-radius: 8px;
    }
    .arrow img {
        width: 100%;
        display: none;
    }
    .checkIcon {
        width: 26px;
        margin-top: -2px;
    }
    .VeriEmailbtn {
        cursor: pointer;
    }
    .boxpic img {
        width: 100%;
    }
    .boxes {
        background: #234555;
        height: 150px;
        border-radius: 10px;
    }
    .starbox {
        background: #fff6c4;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0px 12px 12px rgba(255, 246, 196, .5);
    }
    .StarText {
        color: #000;
        font-size: 18px;
        font-weight: bold;
        text-decoration: none;
    }
    .StarText2 {
        color: #000;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
    }
    .NotesBtn {
        background: #ffd700;
        padding: 8px 25px;
        border: none;
        border-radius: 20px;
        font-size: 16px;
        color: #000;
    }    
}
@media(max-width:768px){
    .Dashbody {
        .statsInBox { 
            padding: 9px 10px;
        }
    }
}
@media(max-width:767px){
    .Dashbody {
        .statsBox.col-md-3 {
            width: 50%;
            float: left;
        }
    }
}
@media(max-width:360px){
    .Dashbody {
        .arrow {
            padding: 25px 6px;
        }        
        .statsInBox p {
            font-size: 35px;
            line-height: 35px;
        }
    }
}

