.MenteereMyEarningsNewPage {
    .ActionBtns {
        background: #fad1a6;
        float: right;
        border-radius: 100px;
    }
    .ActionBtns button {
        background: transparent;
        border: none;
        font-size: 14px;
        padding: 5px 12px;
        line-height: 18px;
        border-radius: 100px;
    }
    .ActionBtns button.active {
        background: #f7b36c;
    }
    .colRed {
        color: red;
    }
    .colGreen {
        color: green;
    }
    .LinkStyle {
        background: transparent;
        padding: 0;
        margin: 0;
        border: none;
        text-decoration: underline;
        color: blue;
    }
    .breakupPopup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.8);
        align-items: center;
        overflow-y: auto;
        text-align: center;
    }
    .breakupPopupBody {
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 5px 10px rgba(0, 0, 0,0.4);
        padding: 20px;
        width: 700px;
        margin-top: 40px;
        margin-bottom: 40px;
        display: inline-block;
        text-align: left;
    }
    .ProfitBoxes p {
        margin: 0;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        opacity: 0.4;
        color: #000;
    }
    .CloseIcon {
        color: #fff;
        position: absolute;
        top: 40px;
        right: 40px;
        cursor: pointer;
    }
    .CloseIcon span {
        font-size: 34px;
    }
}
@media(max-width:500px){
    .MenteereMyEarningsNewPage {
        strong {
            display: block;
            text-align: left;
            margin: 10px 0px;
        }
        .ActionBtns {
            float: left!important;
        }
    }
}