.NotesDetailPage {

    .actionButtons {
        display: flex;
        grid-gap: 20px;
    }   
    .PreviewSide {
        padding-right: 60px;
    }
    .NotesReader {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: #fff;
        display: flex;
        flex-direction: column;
    }
    .NotesReader .pdfViewer {
        flex: 1
    }
    .ReaderHeader {
        padding: 10px 20px;
        align-items: center;
        height: auto;
    }
    
}
.PlanItem {
    margin-bottom: 15px;
    background-size: cover;
    border-radius: 12px;
    overflow: hidden;
    padding: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.planTitle {
    font-size: 22px;
    margin-bottom: 0;
    line-height: 34px;
}
.PlanItem .infos {
    flex: 1;
    padding-right: 14px;
}