.SchoolPlan {
    background: #FFFFFF;
    padding-top: 120px;
    padding-bottom:5px;
}
.SchoolPlan {
    .Max-Width{
        max-width: 100%;
    }
    .SchoolText {
        padding: 120px 0;
        position: relative;
    }
    .SchoolText p {
        font-size: 24px;
        line-height: 50px;
        font-weight: 300;
        text-align: center
    }
    .Billion {
        font-size: 22px;        
        background: #ffd700;
        padding: 0 5px;
    }   
    .Student{
        text-align: center;
        color: #000;
        font-weight: 500;
        font-size: 25px;
        margin-bottom: 40px;
        position: relative;
    }
    .StudentText p {
        font-size: 18px;
        line-height: 28px;
        text-align: left;        
    }    
    .Coaching {      
        display: flex;
    }
    .Coaching img{
        margin-right: 10px;
    }
    .Coaching p {
        margin: 14px 0;
        font-weight: 700;
        font-size: 22px;
    }
    .widthBg{
        width:90%;
        padding-bottom: 10px;
    }
    .cap {
        position: absolute;
        top: 0px;
        left: 100px;
        display: none;
    }
    .arrow {
        position: absolute;
        left: -70px;
        top: 20%;
        display: none;
    }
}
@media(max-width:1199px){
    .SchoolPlan{
        padding: 0 10px;
    }    
}
@media(max-width:1024px){
    .SchoolPlan{
        .Coaching p {           
            font-size: 18px;
        }
    }   

}
@media(max-width:991px){
    .SchoolPlan{
        .Student { 
            margin-bottom: 20px;
            font-size: 19px;
        }
        .Coaching img {
            margin-right: 10px;
            width: 40px;
        }
        .Coaching p {
            margin: 14px 0;
            font-size: 13px;
        }
    }
}

@media(max-width:768px){
    .SchoolPlan{  
        .cycle{
            margin-top:100px;
        }
    }
}
@media(max-width:767px){
    .SchoolPlan{        
        .Student {
            font-size: 20px;
            line-height: 26px;
            font-weight: 400;
            margin: 20px 0;
        }
        .cycle {
            margin-top: 0px;
        }
        .Coaching p {
            margin: 14px 0;           
            text-align: center;
            font-size: 18px;

        }
        .Coaching {
            display: block;
            text-align: center;
        }
        .SchoolText {
            padding: 120px 0 30px;           
        }
        
    }
}