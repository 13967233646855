.MentorshipSec {
    padding: 0px 0 25px 0;
    background: #fff9d6;
    margin-bottom: 10px;
}
.MentorshipSec {
    .SliderSc {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .slick-track {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .slick-arrow span {
        font-size: 22px;
        transition: all 0.2s ease;
        display: inline-block;
        color: #000;
        background: #FFE660;
        border-radius: 50%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
        padding: 11px;
    }
    .slick-arrow span:hover {
        transform: scale(1.2);
    }
    .slick-dots li button:before {
        font-size: 10px;
    }
    .slick-dots {       
        display: none!important;
    }
    .slick-current .ExpertItem {
        background: #FFF;
        z-index: 999;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-top: -25px;
        margin-bottom: 0px;
    
    }
    .slick-slider.slick-initialized > .slick-list {
        padding-top: 20px !important;
    }
    .ExpertItem {
        transition: all 0.2s ease;
        background: #fff;
    }
    .slick-next {
        right: 55px;
    }
    .slick-prev {
        left: 55px;
        z-index: 99;
    }
    .slick-prev:before, .slick-next:before {
        display: none;
    }
    .SecHead {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .MentorImg {
        max-width: 320px;
    }
    .SecHead span {
        font-size: 17px;
        font-weight: 600;
    }
}
@media(max-width:1024px){
    .MentorshipSec{
        img.UniversityImg {
            max-width: 60px;
        }
        img.BadgeImg {       
            width: 60px;
        }
        .ExpertPic {
            width: 70px;
            height: 70px;        
            margin-top: 50px;
        }
        .ExpertItem h4 {
            font-size: 15px;
            margin-bottom: 10px;
        }
    }
}
@media(max-width:767px){
    .MentorshipSec{
        padding: 10px 0 20px 0;
        margin-bottom: 0px;
        .SliderSc .slick-prev {
            left: 0px;
            z-index: 99;
        }
        .SliderSc .slick-next {
            right: 15px;
            z-index: 99;
        }
        .slick-arrow span:hover {
            transform: none;
        }
        .slick-track {
            margin-top: 20px;
            margin-bottom: 0px;
        }
        .MentorImg {
            max-width: 230px;
        }
        .slick-arrow span {
            font-size: 14px;
            padding: 8px;
        }
        .SliderSc {
            margin-top: 0px;
            margin-bottom: 0px;
        }
        .slick-current .ExpertItem {           
            padding-top: 10px;
            padding-bottom: 20px;
            margin-top: 0;
            margin-bottom: 0px;
        }
    }
}