.MySUbscriptionsPage{
    .upperInfo p {
        font-size: 20px;
    }
    .SubItems {
        display: flex;
        grid-gap: 60px;
        margin-top: 30px;
    }
    .statusSign {
        float: right;
        border: 1px solid #000;
        border-radius: 50px;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        grid-gap: 8px;
    }
    .dot {
        background: #00FF3C;
        width: 14px;
        height: 14px;
        border-radius: 50%;
    }
    .SubItem {
        border-radius: 14px;
        overflow: hidden;
        padding: 20px;
        background: #ffe266;
    }
}
@media(max-width:1024px){
    .MySUbscriptionsPage {
        margin: 0;
        .SubItems {
            display: flex;
            grid-gap: 45px;
            margin-top: 10px;
        }
        .upperInfo p {
            font-size: 16px;
        }
        .upperInfo h2 {
            font-size: 22px;
        }
    }
}