.MyPlaylistPage{

    .CoverContainer {
        height: 180px;
        overflow: hidden;
        margin-bottom: 14px;
        position: relative;
        border: 1px solid rgba(0,0,0,0.1);
    }
    .CoverContainer .overlay {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        background: rgba(0,0,0,0.8);
        width: 62%;
    }
    .PlaylistItem {
        margin-bottom: 40px;
    }
    .CoverContainer > img {
        max-height: 150%;
        min-height: 100%;
        min-width: 100%;
    }
    .play-btn {
        text-transform: uppercase;
        font-weight: 700;
        opacity: 0.5;
    }
    .CoverContainer .overlay {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        width: 55%;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .overlay p {
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 18px;
    }
    span.icon {
        font-size: 20px;
        font-weight: bold;
    }
    .PlaylistItem {
        margin-bottom: 40px;
        color: #000;
    }
    .PlaylistItem:hover {
        text-decoration: none;
    }
    .PlaylistItem:hover .overlay {
        background: rgba(0,0,0,0.85);
    }
    .overlay img {
        width: 41px;
    }

}