$simple-line-font-path: "~simple-line-icons/fonts/";
@import "~simple-line-icons/scss/simple-line-icons";

$theme-colors: (
    "primary": #fed703,
);

.btn.btn-primary {
    padding-left: 22px;
    padding-right: 22px;
    border-radius: 50px;
}

.colGreen {
    color: green
}

@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css');
