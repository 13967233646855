.MyNotesPage {
    .NoteItem {
        width: 100%;
        display: inline-block;
        overflow: hidden;
        background: #F5F5F5;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
        margin-bottom: 20px;
    }
    .noteImgCon {
        height: 200px;
        overflow: hidden;
    }
    .noteFeatImg {
        position: relative;
        min-width: 100%;
        max-width: 110%;
        min-height: 100%;
        top: 0;
    }
    .noteContent {
        padding: 15px;
    }
    .noteimg{
        width: 100%;
    }
    .UplodBg{
        background: #fff3b5;
        padding: 15px;
        margin-bottom: 30px;
        border-radius: 15px;
    }
    .NotesText{
        font-weight: 600;
        font-size: 25px;
        color: #000;
    }
    .paranote {
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        color: #000;
    }
    .openBook{
        width: 450px;
    }
    .padd25{
        padding: 25px 0;
    }
    a.btn.btn-info.editBtn:active{
        background: #1b296d;
    }
    a.btn.btn-info.viewBtn:active{
        background: #fed704;
    }
    a.btn.btn-info.deleteBtn:active{
        background: #ff140c;
    }
    .editBtn {
        margin-right: 5px;
        background: #1b296d;
        border-color: #1b296d;
    }
    .viewBtn {
        margin-right: 5px;
        background: #fed704;
        border-color: #fed704;
    }
    .deleteBtn {       
        background: #ff140c;
        border-color: #ff150d;
    }
    .IconWidth{
        width: 18px;
        height: 18px;
    }
}
@media(max-width:1199px){
    .MyNotesPage {
        .padd25 {
            padding: 25px 0px 25px 70px;
        }        
    }
}
@media(max-width:1199px){
    .MyNotesPage {
        .UplodBg {
            margin: 0 0 15px 0;
        }
    }
}

@media(max-width:768px){
    .MyNotesPage {
        padding-bottom: 20px;
        .padd25 {
            padding: 15px 0px 15px 130px;
        }
        .NotesText {
            font-weight: 600;
            font-size: 16px;
        }
        .noteImgCon {
            height: auto;
            overflow: hidden;
        }
        .noteContent {
            padding: 7px;
        }
        .form-control {
            height: auto;
        }
        
    }
}
@media(max-width:767px){
    .MyNotesPage {
        .padd25 {
            padding: 15px 0px 15px 20px;
        }
        .NotesText {
            font-weight: 600;
            font-size: 20px;
        }
        .paranotes br {
            display: none;
        }
        
    }
}