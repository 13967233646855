.MyPlaylistDetailPage{

    .CoverContainer {
        overflow: hidden;
        margin-bottom: 14px;
        position: relative;
    }
    .PlaylistItem {
        margin-bottom: 40px;
    }
    .CoverContainer > img {
        max-width: 170px;
        border: 1px solid rgba(0,0,0,0.1);
    }
    .play-btn {
        text-transform: uppercase;
        font-weight: 700;
        opacity: 0.5;
    }
    .CoverContainer .overlay {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        width: 55%;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .overlay p {
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 18px;
    }
    span.icon {
        font-size: 20px;
        font-weight: bold;
    }
    .notItem {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #ccc;
    }
    .coverSide img {
        max-width: 100%;
    }
    .coverSide {
        height: 120px;
        border: 1px solid #ccc;
        overflow: hidden;
        width: 100px;
    }
    .notItem {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #ccc;
        align-items: center;
        grid-gap: 20px;
        padding: 30px 0;
        color: #000;
    }
    .notItem:hover {
        text-decoration: none;
        background: #f2f2f2;
    }
    .contSide {
        flex: 1;
    }
    .contSide p {
        margin-bottom: 14px;
        max-height: 52px;
        overflow: hidden;
        opacity: 0.6;
    }
    .notelist {
        background: #f9f9f9
    }
    .notItem:last-child {
        border: none;
    }

}