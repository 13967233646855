.LoginSec {
    .CoverArea {
        position: fixed;
        height: 100%;
        width: 50%;
        align-items: center;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        padding: 0;
    }
    .MainArea {
        left: 50%;
        display: flex;
        flex-direction: column;
    }
    .LogoCircle {
        transform: translateX(50%);
    }
    .FormTitle {
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 26px;
    }
    .Form {
        align-items: center;
        display: flex;
        padding-left: 100px;
        padding-right: 100px;
        flex-wrap: wrap;
        justify-content: center;
        height: 100%;
    }
    .FormBody {
        width: 400px;
        margin-top: 150px
    }
    label {
        margin-bottom: 14px;
        margin-top: 8px;
    }
    .form-control {
        border-radius: 9px;
        padding: 8px 20px;
        height: auto;
    }
    .btm-link {
        color: #1492E6
    }
    .UpperMenu {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 22px;
        position: relative;
        z-index: 999
    }  
    .UpperMenu li{
        list-style: none;
    }
    .UpperMenu li a {
        margin: 0 0px;
        padding: 10px 5px;
        font-weight: 700;
        font-size: 16px;
        color: #000;
    }  
    .UpperMenu > a {
        color: #000;
        margin: 0 30px;        
        padding: 10px 5px;
        font-weight: 700;
        font-size: 16px;
        white-space: nowrap;
        border-bottom: 5px solid transparent;
        text-decoration: none;
        transition: all .5s ease-in-out;
    }
    .UpperMenu > a:hover{
        border-color: #fed703;
    }
    .TimesNone{
        display: none;
    }
    .MyBaar {
        background: #ffd700;
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        border-radius: 4px;
        color: #000;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, .1);
        display: none;
        position: fixed;
        right: 20px;
        top: 10px;
        z-index: 99;
    }
    img.Logo {
        display: none;
    } 
}
@media(max-width:1350px){  
    .LoginSec {      
        .UpperMenu>a {
            margin: 0 10px;
            padding: 0 5px;
            font-weight: 700;
            font-size: 16px;
        }        
    }
}
@media(max-width:1024px){  
    .LoginSec {   
        .Form {
            padding:0px 70px;
        }
        .LogoCircle {
            position: absolute;
            top: 20%;
            width: 120px;
        }
        .UpperMenu > a:hover {
            border-color: transparent;
        }
    }
}
@media(max-width:1024px){  
    .LoginSec {  
        .UpperMenu>a {
            margin: 0 5px;
        }         
    }
}
@media(max-width:768px){  
    .LoginSec {
        .UpperMenu > a {
            margin: 0 0px;
            padding: 10px 0 ;
            font-size: 14px;
        }  
        .LogoCircle {
            width: 100px;
            top: 30%;
        }
        .Form {
            padding: 0px 40px;
        }        
    }
}
@media(max-width:767px){  
    .LoginSec { 
        padding: 15px;
        .MyBaar {
            width: 30px;
            height: 30px;
            line-height: 30px; 
            display: block;
            border-radius: 50px;
           
        }
        .TimesNone{
            display: block;
            font-size: 16px;
            margin-left: 0px;
        }
        .UpperMenu {
            display: none;
        }        
        .UpperMenu.active-right {
            position: fixed;
            display: block;            
            top: 0;            
            right: 0px;
            background: #fff9d6;
            width: 250px;
            height: 100%;
            padding: 20px;
        }
        .UpperMenu.active-right a {
            display: block;
            margin: 0px 0px 5px;          
        }
        .CoverArea{
            display: none;
        }
        .MainArea {
            left: 0;
            display: block;
            flex-direction: initial;
        }          
        .Form {
            padding: 0px;
        }
        .FormBody {
            width: 100%;
            margin-top: 50px;
        }
        img.Logo {
            display: inline-block;
            width: 120px;
        }
        .FormTitle {
            font-size: 18px;
            margin: 15px 0;
        }
        .SubmitBtns {
            font-size: 14px;
            display: block;
            padding: 2px 20px !important;
            margin: 0px auto 0px;
        }
        .text-right {
            text-align: center !important;
            margin: 10px 0 0;
        }
        label {
            font-size: 14px;
        }
        img.Logo {
            display: block;
        }        
    }
}
