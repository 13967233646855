.NotesListBanner {
    margin-top: 100px;
    background: #ffee93;
    padding: 20px 0;
}

.NotesListBanner {
    .bannerContent {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        grid-gap: 20px;
    }
    .bannerContent p {
        font-size: 16px;
        color: #000;
    }
    .bannerImg {
        max-width: 450px;
        width: 100%;
    }
    .noteform{
        background: #fff5bc;
        border-radius: 15px;
    } 
}