.NoteItem {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 30px;
    padding: 15px;
    display: inline-block;
    color: #000;
}
.NoteItem:hover, .NoteItem:focus {
    text-decoration: none;
    color: #000;
}
.NoteItem {
    .NoteItemUp {
        display: flex;
    }
    .cover {
        flex: 1 1;
        overflow: hidden;
        border-radius: 12px;
        position: relative;
        border: 2px solid #C6E4DE;
        height: 210px;
    }
    .cover img {
        position: absolute;
        max-width: 120%;
        min-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .authorMeta {
        flex: 1;
        overflow: hidden;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
    }
    .universityImg {
        max-width: 60%;
        margin-bottom: 6px;
    }
    .AuthorImg {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 6px;
        position: relative;
    }
    .AuthorImg img {
        min-width: 100%;
        max-width: 120%;
        min-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .authorMeta span {
        font-size: 16px;
        opacity: 0.6;
    }
    .authorMeta p {
        font-size: 18px;
    }
    .NoteItemBtm {
        margin-top: 20px;
        display: flex;
        align-items: center;
    }
    .NoteName {
        flex: 1;
        padding-right: 14px;
    }
    .NoteAction {
        font-size: 14px;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
    }
    .NoteAction span {
        margin-left: 5px;
        font-size: 20px;
        vertical-align: text-bottom;
    }
}
@media(max-width:1024px){
    .NoteItem {
        .cover {    
            height: 170px;
        }
    }       
}
@media(max-width:767px){
    .NoteItem {
        .cover {
            height: 170px;
        }
        .universityImg {
            max-width: 40%;
            margin-bottom: 4px;
        }
    }
}